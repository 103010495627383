import { FaAngleRight } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

function BusinessCardsClient() {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center gap-3 mb-10 text-sm text-slate-500">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/d/overview");
          }}
        >
          Business
        </div>
        <div>
          <FaAngleRight />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/d/services/details");
          }}
        >
          Services
        </div>
        <div>
          <FaAngleRight />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/d/services/cards");
          }}
        >
          Cards
        </div>
      </div>

      <div className="mb-10 text-xl">Cards</div>

      <div className="flex items-center justify-center gap-5 mb-8">
        <center>
          <div className="mb-5 text-[50px]">
            <BiSupport />
          </div>
          <div>
            Kindly Contact{" "}
            <Link
              to={"mailto:hello@lucrestack.com"}
              className="font-bold text-purple-600 dark:text-purple-400"
            >
              support
            </Link>
          </div>
        </center>
      </div>
    </>
  );
}

export default BusinessCardsClient;
