"use client";

import React, { useEffect, useMemo, useState } from "react";
import http from "@/lib/http";
import { Skeleton, Toast, useToast } from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import AutoGenTableViewModal from "@/components/modals/AutoGenTableViewModal";
import FilterForm from "@/components/FilterForm";
import { IPayments } from "@/types/global.types";
import ViewManageProductModal from "@/components/modals/ViewManageProductModal";

function BusinessServicesPaymentsDetailsClient() {
  const navigate = useNavigate();
  const [details, setDetails] = useState<IPayments[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const toast = useToast();

  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    currency: "NGN",
    collection: "",
    active: "",
    // type: "",
    order_by: "",
    // reference_code: ""
    // date_filter_from: "",
    // date_filter_to: ""
    id: "",
  };
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchServices() {
    try {
      const resp = await http("services/addon/payments/list", { limit: 10 });
      // console.log("resp", resp)
      setDetails(resp.data);
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  // function updateService(service_id: string, newActive: string) {
  //     const index = details.findIndex(service => service.service_id === service_id);
  //     if (index !== -1) {
  //         const updatedServices = [...details];
  //         updatedServices[index].active = newActive;
  //         setDetails(updatedServices);
  //     }
  // };

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDetails = useMemo(() => {
    if (details.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? details
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
            )
        : sort === "date-desc"
        ? details
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
            )
        : sort === "z-a"
        ? details
            .slice()
            .sort((a: any, b: any) =>
              b.name.toLowerCase() < a.name.toLowerCase() ? -1 : 1
            )
        : details
            .slice()
            .sort((a: any, b: any) =>
              a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            );

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            e.name.toLowerCase().includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, details]);

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/overview");
            }}
          >
            Business
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/details");
            }}
          >
            Services
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/payments");
            }}
          >
            Payments
          </div>
        </div>
      </div>

      <div className="mb-10 text-xl">Payments Details</div>

      <div className="flex items-center gap-5 mb-8">
        <input
          value={search}
          type="text"
          className="px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Enter Search Keyword"
        />
        <div className="flex-1">
          <div className="text-xs">Sort by</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className="mb-6 border-slate-300 dark:border-neutral-700" />

      <FilterForm
        url="services/addon/payments/list"
        inputs={filterInputs}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setDetails}
        setPaginationInput={setPaginationInput}
        inputtedIsSelectTag={
          {
            // type: ["DIRECT", "ALTERNATIVE"]
          }
        }
      />

      <div className="flex items-center justify-between mb-6">
        <div
          className="text-sm font-bold text-purple-500 underline cursor-pointer underline-offset-4"
          onClick={() => navigate("/d/services/payments/requests")}
        >
          View Payments Requests
        </div>
        <div
          className="flex gap-3 px-2 py-2 bg-purple-800 cursor-pointer text-neutral-100"
          onClick={() => navigate("/d/services/payments/create")}
        >
          {/* <FiUserPlus /> */}
          <div className="text-xs">Create payments</div>
        </div>
      </div>

      <div className="w-full mb-10">
        <div className="relative overflow-auto max-h-[500px] flex-1">
          <table className="min-w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
              <tr>
							<th scope="col" className="px-6 py-3">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Collection
                </th>
                <th scope="col" className="px-6 py-3">
                  Currency
                </th>
                <th scope="col" className="px-6 py-3">
                  Min Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  max Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Active
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!isFetching
                ? filteredDetails.map((d, k) => (
                    <tr
                      key={k}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
											 <td className="px-6 py-4">{d.id}</td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {d.name}
                      </th>
                      <td className="px-6 py-4">{d.collection}</td>
                      <td className="px-6 py-4 uppercase">{d.currency}</td>
                      <td className="px-6 py-4">{d.min_amount}</td>
                      <td className="px-6 py-4">{d.max_amount}</td>
                      <td className="px-6 py-4">{d.active}</td>
                      <td className="flex gap-3 px-6 py-4">
                        <AutoGenTableViewModal title="Payments" data={d}>
                          <span className="font-bold text-blue-600">View</span>
                        </AutoGenTableViewModal>
                        <p
                          className="font-bold text-blue-600 cursor-pointer"
                          onClick={() => {
                            navigate(`/d/services/payments/update/${d.id}`);
                          }}
                        >
                          Update
                        </p>
                        <ViewManageProductModal title="Payment Manger" data={d}>
                          <span className="font-bold text-blue-600">
                            Manager
                          </span>
                        </ViewManageProductModal>
                        {/* <UpdateBusinessServiceModal data={d} updateService={updateService}><span className='font-bold text-blue-600'>Update</span></UpdateBusinessServiceModal> */}
                      </td>
                    </tr>
                  ))
                : Array.from(Array(5)).map((k, i) => (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Skeleton h={"20px"} />
                      </td>
											<td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default BusinessServicesPaymentsDetailsClient;
