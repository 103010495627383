"use client";

import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import http from "@/lib/http";
import { useToast } from "@chakra-ui/react";
// import classNames from 'classnames';
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";
import { IUpdatePayments } from "@/types/global.types";
import { removeUnderscore, upperCaseFirst } from "@/utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "antd";

function BusinessServicesPaymentsUpdateClient() {
  // const { user } = useApp();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const [form] = Form.useForm();

  const notRequired = [
    "video",
    "image_col_1",
    "image_col_2",
    "image_col_3",
    "image_col_4",
  ];

  const instalmentRelated = [
    "instalment_min_count",
    "instalment_max_count",
    "instalment_min_duration",
    "instalment_max_duration",
  ];

  const [isSending, setIsSending] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isSelectTag, setIsSelectTag] = useState({
		currency: ["NGN", "USD"],
    category: [],
    active: ["Yes", "No"],
    auto_approve: ["Yes", "No"],
    auto_decline: ["Yes", "No"],
    send_late_repayment_sms: ["Yes", "No"],
    send_late_repayment_email: ["Yes", "No"],
    required: ["Yes", "No"],
    send_request_status_sms: ["Yes", "No"],
    send_request_status_email: ["Yes", "No"],
    instalment: ["Yes", "No"],
  });

  const [upadePaymentsInput, setUpdatePaymentsInput] =
    useState<IUpdatePayments>({
      // "id": "",
      currency: "",
      quantity: "",
      name: "",
      description: "",
      collection: "",
      category: "",
      min_amount: "",
      max_amount: "",
      active: "",
      auto_approve: "",
      auto_decline: "",
      send_request_status_sms: "",
      send_request_status_email: "",
      video: "",
      image_col_1: "",
      image_col_2: "",
      image_col_3: "",
      image_col_4: "",
      //@ts-ignore
      instalment: "",
      instalment_min_count: "",
      instalment_max_count: "",
      instalment_min_duration: "",
      instalment_max_duration: "",
      request_info: [],
    });

  const isSelectTagRequireInfo = {
    required: ["Yes", "No"],
    type: [
      "text",
      "alphanumeric",
      "number",
      "video",
      "image",
      "audio",
      "select",
    ],
  };

  async function getPayments() {
    // console.log(upadePaymentsInput)
    try {
      const resp = await http("services/addon/payments/list", {
        id: params.id,
      });
      console.log("list resp", resp.data);
      if (!resp.data || resp.data.length === 0) {
        navigate("/d/services/payments");
      } else {
        // setUpdatePaymentsInput(resp.data[0]);
        setUpdatePaymentsInput({
          // currency: resp.data[0]?.currency,
          quantity: resp.data[0]?.quantity,
          name: resp.data[0]?.name,
          description: resp.data[0]?.description,
          collection: resp.data[0]?.collection,
          category: resp.data[0]?.category,
          min_amount: resp.data[0]?.min_amount,
          max_amount: resp.data[0]?.max_amount,
          active: resp.data[0]?.active,
          auto_approve: resp.data[0]?.auto_approve,
          auto_decline: resp.data[0]?.auto_decline,
          send_request_status_sms: resp.data[0]?.send_request_status_sms,
          send_request_status_email: resp.data[0]?.send_request_status_email,
          video: resp.data[0]?.video,
          image_col_1: resp.data[0]?.image_col_1,
          image_col_2: resp.data[0]?.image_col_2,
          image_col_3: resp.data[0]?.image_col_3,
          image_col_4: resp.data[0]?.image_col_4,
          //@ts-ignore
          instalment: resp.data[0]?.instalment?.allowed,
          instalment_min_count: resp.data[0]?.instalment?.min_count,
          instalment_max_count: resp.data[0]?.instalment?.max_count,
          instalment_min_duration: resp.data[0]?.instalment?.min_duration,
          instalment_max_duration: resp.data[0]?.instalment?.max_duration,
          request_info: resp.data[0]?.request_info,
        });
        form.setFieldsValue({
          // currency: resp.data[0]?.currency,
          quantity: resp.data[0]?.quantity,
          name: resp.data[0]?.name,
          description: resp.data[0]?.description,
          collection: resp.data[0]?.collection,
          category: resp.data[0]?.category,
          min_amount: resp.data[0]?.min_amount,
          max_amount: resp.data[0]?.max_amount,
          active: resp.data[0]?.active,
          auto_approve: resp.data[0]?.auto_approve,
          auto_decline: resp.data[0]?.auto_decline,
          send_request_status_sms: resp.data[0]?.send_request_status_sms,
          send_request_status_email: resp.data[0]?.send_request_status_email,
          video: resp.data[0]?.video,
          image_col_1: resp.data[0]?.image_col_1,
          image_col_2: resp.data[0]?.image_col_2,
          image_col_3: resp.data[0]?.image_col_3,
          image_col_4: resp.data[0]?.image_col_4,
          //@ts-ignore
          instalment: resp.data[0]?.instalment?.allowed,
          instalment_min_count: resp.data[0]?.instalment?.min_count,
          instalment_max_count: resp.data[0]?.instalment?.max_count,
          instalment_min_duration: resp.data[0]?.instalment?.min_duration,
          instalment_max_duration: resp.data[0]?.instalment?.max_duration,
        });
        setIsFetching(false);
      }
    } catch (error) {
      console.log(error);
      handleResponseError(error, toast);
      navigate("/d/services/payments");
    }
  }

  async function getCategories() {
    // console.log(upadePaymentsInput)
    try {
      const resp = await http<{
        name: string;
        description: string;
      }>("services/addon/payments/category");
      console.log("category resp", resp.data);
      setIsSelectTag((t) => ({
        ...t,
        category: resp.data.map(
          (e: { name: string; description: string }) => e.name
        ),
      }));
    } catch (error) {
      console.log(error);
      handleResponseError(error, toast);
    }
  }

  useEffect(() => {
    getPayments();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updatePayments() {
    setIsSending(true);
    console.log(upadePaymentsInput);
    const {
      //@ts-ignore
      instalment,
      //@ts-ignore
      instalment_max_count,
      //@ts-ignore
      instalment_min_count,
      //@ts-ignore
      instalment_max_duration,
      //@ts-ignore
      instalment_min_duration,
      ...rest
    } = upadePaymentsInput;
    try {
      const resp = await http("services/addon/payments/update", {
        ...rest,
        instalment: {
          allowed: instalment,
          max_count: instalment_max_count,
          min_count: instalment_min_count,
          max_duration: instalment_max_duration,
          min_duration: instalment_min_duration,
        },
        id: params.id,
				currency: rest?.currency?.toLowerCase()
      });
      console.log("update resp", resp.data);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/d/services/payments");
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    fieldName: string
  ) => {
    setUpdatePaymentsInput((prevInput) => ({
      ...prevInput,
      [fieldName]: event.target.value,
    }));
  };

  const handleInputChange_require_info = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string,
    index: number
  ) => {
    if (fieldName !== "options") {
      setUpdatePaymentsInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index ? { ...info, [fieldName]: event.target.value } : info
        ),
      }));
    } else {
      setUpdatePaymentsInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index
            ? {
                ...info,
                [fieldName as any]: formatRequiredInfoOptionsStore(
                  event.target.value
                ),
              }
            : info
        ),
      }));
    }
  };

  function deleteRequestInfo(index: number) {
    setUpdatePaymentsInput((prevInput) => ({
      ...prevInput,
      request_info: prevInput.request_info.filter((_, i) => i !== index),
    }));
  }

  function addMoreRequestInfo() {
    setUpdatePaymentsInput((prevInput) => ({
      ...prevInput,
      request_info: [
        ...prevInput.request_info,
        {
          name: "",
          required: "",
          options: [],
          value: "",
          type: "",
        },
      ],
    }));
  }

  function formatRequiredInfoOptionsView(data: string[]): string {
    if (data.length > 0) {
      let r = "";
      data.forEach((e, i) => {
        if (i !== data.length - 1) {
          r += e + ",";
        } else {
          r += e;
        }
      });
      return r;
    }
    return "";
  }

  function formatRequiredInfoOptionsStore(data: string): string[] {
    const arr = data.split(",");
    let s: string[] = [];
    if (arr.length > 0) {
      arr.forEach((e) => {
        s.push(e);
      });
      return s;
    }
    return [];
  }

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/overview");
            }}
          >
            Business
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/details");
            }}
          >
            Services
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/payments");
            }}
          >
            Payments
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(`/d/services/payments/update/${params.id}`);
            }}
          >
            Update
          </div>
        </div>
      </div>

      <div className="mb-8 text-xl">Update Payments</div>
      <hr className="mb-8 border-slate-300 dark:border-neutral-700" />

      {!isFetching ? (
        <div className="w-full mb-10">
          <Form form={form} className="mb-8" onFinish={updatePayments}>
            <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold dark:text-white">
              Payments Information
            </div>
            <div className="grid grid-cols-1 gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4">
              {Object.keys(upadePaymentsInput)
                .filter(
                  (f) =>
                    // f !== "instalment" &&
                    f !== "request_info" && f !== "description"
                )
                .map((fieldName, i) => (
                  <div key={i} className="">
                    <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]">
                      {upperCaseFirst(removeUnderscore(fieldName))}
                    </div>
                    {Object.keys(isSelectTag).includes(fieldName) ? (
                      <Form.Item
                        name={fieldName}
                        rules={[
                          {
                            required: true,
                            message: `${upperCaseFirst(
                              removeUnderscore(fieldName)
                            )} is required`,
                          },
                        ]}
                      >
                        <select
                          value={(upadePaymentsInput as any)[fieldName]}
                          className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                          onChange={(event) => {
                            handleInputChange(event, fieldName);
                            form.setFieldValue(fieldName, event.target.value);
                          }}
                          disabled={isSending}
                        >
                          <option value="">Select</option>
                          {((isSelectTag as any)[fieldName] as string[]).map(
                            (e, k) => (
                              <option value={e} key={k}>
                                {e}
                              </option>
                            )
                          )}
                        </select>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name={fieldName}
                        rules={[
                          {
                            required: notRequired.includes(fieldName)
                              ? false
                              : instalmentRelated.includes(fieldName)
                              ? form.getFieldValue("instalment") === "Yes"
                                ? true
                                : false
                              : true,
                            message: `${upperCaseFirst(
                              removeUnderscore(fieldName)
                            )} is required`,
                          },
                        ]}
                      >
                        <input
                          type="text"
                          value={(upadePaymentsInput as any)[fieldName]}
                          className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                          onChange={(event) => {
                            handleInputChange(event, fieldName);
                            form.setFieldValue(fieldName, event.target.value);
                          }}
                          placeholder={upperCaseFirst(
                            removeUnderscore(fieldName)
                          )}
                          disabled={isSending}
                        />
                      </Form.Item>
                    )}
                  </div>
                ))}

              <div className="col-span-1 mb-8 md:col-span-2 lg:col-span-4">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]">
                  Description
                </div>
                <Form.Item
                  name={"description"}
                  rules={[
                    {
                      required: true,
                      message: `${upperCaseFirst(
                        removeUnderscore("description")
                      )} is required`,
                    },
                  ]}
                >
                  <textarea
                    value={upadePaymentsInput.description}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(event) => {
                      handleInputChange(event, "description");
                      form.setFieldValue("description", event.target.value);
                    }}
                    placeholder="Description"
                    rows={3}
                    disabled={isSending}
                  ></textarea>
                </Form.Item>
              </div>
            </div>

            <div className="mb-10">
              <div className="flex items-center justify-between mb-5 text-sm">
                <div className="border-b-2 border-purple-800 pb-[3px] font-bold dark:text-white">
                  Request information
                </div>
                <div
                  className="text-xs font-bold text-purple-700 cursor-pointer dark:text-purple-500"
                  onClick={() => addMoreRequestInfo()}
                >
                  Add section
                </div>
              </div>
              {upadePaymentsInput.request_info.length > 0 ? (
                upadePaymentsInput.request_info.map((request_info, index) => (
                  <div
                    key={index}
                    className="p-3 mb-3 rounded-lg bg-slate-100 dark:bg-neutral-800"
                  >
                    <div className="grid grid-cols-1 gap-5 mb-3 md:grid-cols-2 lg:grid-cols-4">
                      {Object.keys(request_info).map((fieldName, i) => (
                        <div key={i} className="">
                          <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]">
                            {upperCaseFirst(removeUnderscore(fieldName))}
                          </div>
                          {Object.keys(isSelectTagRequireInfo).includes(
                            fieldName
                          ) ? (
                            <select
                              value={
                                fieldName === "options"
                                  ? formatRequiredInfoOptionsView(
                                      (
                                        upadePaymentsInput.request_info[
                                          index
                                        ] as any
                                      )[fieldName]
                                    )
                                  : (
                                      upadePaymentsInput.request_info[
                                        index
                                      ] as any
                                    )[fieldName]
                              }
                              className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                              onChange={(event) =>
                                handleInputChange_require_info(
                                  event,
                                  fieldName,
                                  index
                                )
                              }
                              disabled={isSending}
                            >
                              <option value="">Select</option>
                              {(
                                (isSelectTagRequireInfo as any)[
                                  fieldName
                                ] as string[]
                              ).map((e, k) => (
                                <option value={e} key={k}>
                                  {e}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              value={
                                fieldName === "options"
                                  ? formatRequiredInfoOptionsView(
                                      (
                                        upadePaymentsInput.request_info[
                                          index
                                        ] as any
                                      )[fieldName]
                                    )
                                  : (
                                      upadePaymentsInput.request_info[
                                        index
                                      ] as any
                                    )[fieldName]
                              }
                              className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                              onChange={(event) =>
                                handleInputChange_require_info(
                                  event,
                                  fieldName,
                                  index
                                )
                              }
                              placeholder={
                                fieldName === "options"
                                  ? "option1, option2, option3"
                                  : upperCaseFirst(removeUnderscore(fieldName))
                              }
                              disabled={isSending}
                            />
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="mb-2">
                      <div
                        className="text-xs text-red-500 cursor-pointer"
                        onClick={() => deleteRequestInfo(index)}
                      >
                        delete section
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-3 mb-3 text-sm rounded-lg bg-slate-100 dark:bg-neutral-800">
                  Click on "Add section" to add form group
                </div>
              )}
            </div>

            <div>
              <button
                className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                // onClick={() => updatePayments()}
                disabled={isSending}
                type="submit"
              >
                {isSending ? (
                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </Form>
        </div>
      ) : (
        <div className="mb-10 h-[300px] w-full flex justify-center items-center">
          <ImSpinner8 className="w-10 h-10 text-purple-800 animate-spin" />
        </div>
      )}
    </>
  );
}

export default BusinessServicesPaymentsUpdateClient;
