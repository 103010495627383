'use client'

import React, { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom';
import Loader from './Loader';
import BusinessesLayout from './BusinessesLayout';
import { useApp } from '@/contexts/appContext';
import PanelHeader from './PanelHeader';

function BusinessLayoutClient() {

    const { user } = useApp();
    const navigate = useNavigate();
    const { isAuthenticating, isAuthenticated } = useApp();

    useEffect(() => {
        if (!isAuthenticating && !isAuthenticated) {
            localStorage.removeItem('app-tkn');
            navigate('/auth/login', { replace: true });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticating, isAuthenticated]);

    return (
        <>
            {!isAuthenticating && isAuthenticated ?
                <div className='font-container'>
                    <PanelHeader />
                    <div className='bg-white dark:bg-neutral-900 min-h-lvh dark:text-neutral-200'>
                        <div className='md:flex'>
                            <BusinessesLayout name={user!.business!.name} />
                            <div className='p-5 md:flex-1'><Outlet /></div>
                        </div>
                    </div>
                </div> : <Loader />}
        </>
    )
}

export default BusinessLayoutClient