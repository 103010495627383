'use client'

import React, { useEffect, useMemo, useState } from 'react'
import { GrDocumentText } from 'react-icons/gr';
import http from '@/lib/http';
import { Skeleton } from '@chakra-ui/react';
import UpdateKycModal from '@/components/modals/UpdateKycModal';
import AutoGenNormalViewModal from '@/components/modals/AutoGenNormalViewModal';
import FilterForm from '@/components/FilterForm';

function KycClient() {

  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [details, setDetails] = useState<any[]>([]);

  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    country_code: "",
    account_type: "",
    order_by: ""
  }
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage
    });
  }

  async function fetchKycs() {
    try {
      const resp = await http("kyc/details", {limit: 10});
      // console.log("kycs", resp)
      setDetails(resp.data)
      setPaginate(resp)
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
    }
  }

  function updateKyc(kyc_id: string, accountType: string, newActive: string) {
    const index = details.findIndex(kyc => kyc.kyc_id === kyc_id);
    if (index !== -1) {
      const updatedKycs = [...details];
      updatedKycs[index].active = newActive;
      updatedKycs[index].account_type = accountType;
      setDetails(updatedKycs);
    }
  };

  useEffect(() => {
    fetchKycs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDetails = useMemo(() => {
    if (details.length === 0) return [];
    //  if(search === "");
    const sorted_ = sort === "date-asc" ?
      details.slice().sort((a: any, b: any) => new Date(a.created_on).getTime() - new Date(b.created_on).getTime()) :
      sort === "date-desc" ?
        details.slice().sort((a: any, b: any) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime()) :
        sort === "z-a" ?
          details.slice().sort((a: any, b: any) => b.kyc_name.toLowerCase() < a.kyc_name.toLowerCase() ? -1 : 1) :
          details.slice().sort((a: any, b: any) => a.kyc_name.toLowerCase() < b.kyc_name.toLowerCase() ? -1 : 1);

    const filtered_ = search.length > 0 ?
      sorted_.filter((e: any) => e.kyc_name.toLowerCase().includes(search.toLowerCase())) :
      sorted_;

    return filtered_;

  }, [search, sort, details]);

  return (
    <div>

      <div className='mb-10 text-xl'>KYC</div>

      <div className='mb-8 flex gap-5 items-center'>
        <input value={search} type="text" className='px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setSearch(e.target.value)} placeholder='Enter Search Keyword' />
        <div className='flex-1'>
          <div className='text-xs'>Sort by</div>
          <select className='text-xs p-1 dark:bg-neutral-700 w-[110px]' value={sort} onChange={(e) => setSort(e.target.value)}>
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className='mb-10 border-slate-300 dark:border-neutral-700' />

      <FilterForm
        url="kyc/details"
        inputs={filterInputs}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setDetails}
        setPaginationInput={setPaginationInput}
        inputtedIsSelectTag={{ account_type: ["Starter", "Rated", "Standard", "Merchant"] }}
      />

      <div className='mb-10 w-full'>

        <div className="relative overflow-auto max-h-[500px]">
          <table className="w-full text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-[11px] text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  KYC Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Active
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                !isFetching ? filteredDetails.map((d, k) => (
                  <tr key={k} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex gap-3">
                      <GrDocumentText />
                      <div>{d.kyc_name}</div>
                    </th>
                    <td className="px-6 py-4">
                      {d.active}
                    </td>
                    <td className="px-6 py-4 flex gap-3">
                      <AutoGenNormalViewModal exclude={["kyc_id"]} title='KYC' data={d}><span className='font-bold text-blue-600'>View</span></AutoGenNormalViewModal>
                      <UpdateKycModal data={d} updateKyc={updateKyc}><span className='font-bold text-blue-600'>Update</span></UpdateKycModal>
                    </td>
                  </tr>)) :
                  Array.from(Array(5)).map((k, i) => (
                    <tr key={i} className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700">
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Skeleton h={'20px'} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={'20px'} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={'20px'} />
                      </td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default KycClient