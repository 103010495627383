'use client'

import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';

function UserUpdateKycModal({
  children,
  data,
  id,
  updateKyc
}: {
  children: React.ReactNode;
  data: any;
  id: string;
  updateKyc: (parameter: string, status: string) => void
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [status, setStatus] = useState<string>(data.status);
  const [reason, setReason] = useState<string>(data.account_type);
  const [isFetching, setIsFetching] = useState(false);

  async function kycUpdate() {
    setIsFetching(true);
    try {
      const resp = await http("user/kyc/update", { user_id: id, parameter: data.parameter, reason, status });
      // console.log("resp", resp.data)
      // updateKyc(data.parameter, reason)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      // onClose();
      // setIsFetching(false);
      setTimeout(() => window.location.reload(), 1000)
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Update user KYC</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <div className='mb-3'>
              <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{data.parameter}</div>
              {/* <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Account type</div>
                <select value={reason} onChange={(e) => setReason(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={true}>
                  <option value="">Select an option</option>
                  <option value="Starter">Starter</option>
                  <option value="Rated">Rated</option>
                  <option value="Standard">Standard</option>
                  <option value="Merchant">Merchant</option>
                </select>
              </div> */}
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Active</div>
                <select value={status} onChange={(e) => setStatus(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching}>
                  <option value="">Select an option</option>
                  <option value="Failed">Failed</option>
                  <option value="Successful">Successful</option>
                </select>
              </div>
              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Reason (optional)</div>
                <input type='text' value={reason} onChange={(e) => setReason(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' placeholder='Reason' disabled={true} />
              </div>

              <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => kycUpdate()} disabled={isFetching}>
                {
                  isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                }
              </button>
            </div>
          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserUpdateKycModal