"use client";

import React, { useEffect, useMemo, useState } from "react";
import AutoGenNormalViewModal from "@/components/modals/AutoGenNormalViewModal";
// import { useApp } from '@/contexts/appContext';
import { httpRaw } from "@/lib/http";
import { Skeleton, useToast } from "@chakra-ui/react";
import classNames from "classnames";
import { FaAngleRight } from "react-icons/fa6";
import AddTransferRecipientModal from "@/components/modals/AddTransferRecipientModal";
import { FiUserPlus } from "react-icons/fi";
import { handleResponseError } from "@/utils/errors";
import FilterForm from "@/components/FilterForm";
import { useNavigate } from "react-router-dom";

function BusinessWalletTransferRecipientClient() {
  const navigate = useNavigate();

  const toast = useToast();
  const [tab, setTab] = useState<"external" | "internal">("external");
  const [recipientsInternal, setRecipientsInternal] = useState<any[]>([]);
  const [recipientsExternal, setRecipientsExternal] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [paginationInputInternal, setPaginationInputInternal] = useState<any>();
  const filterInputsInternal = {
    currency: "NGN",
    order_by: "",
  };
  function setPaginateInternal(resp: any) {
    setPaginationInputInternal({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  const [paginationInputExternal, setPaginationInputExternal] = useState<any>();
  const filterInputsExternal = {
    currency: "NGN",
    name: "",
    number: "",
    order_by: "",
    date_filter_from: "",
    date_filter_to: "",
  };
  function setPaginateExternal(resp: any) {
    setPaginationInputExternal({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchServicesInternal() {
    try {
      const resp = await httpRaw(
        "https://api.lucrestack.co/wallet/transfer/internal/ngn/recipient/details",
        { limit: 10 }
      );
      console.log("resp", resp);
      setRecipientsInternal(resp.data);
      setPaginateInternal(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  async function fetchServicesExternal() {
    try {
      const resp = await httpRaw(
        "https://api.lucrestack.co/wallet/transfer/external/ngn/recipient/details",
        { limit: 10 }
      );
      console.log("resp", resp);
      setRecipientsExternal(resp.data);
      setPaginateExternal(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  async function removeRecipientExtrernal(
    recipient_id: string,
    currency: string
  ) {
    // setIsFetching(true);
    if (window.confirm("Are you sure")) {
      try {
        const resp = await httpRaw(
          "https://api.lucrestack.co/wallet/transfer/recipient/external/ngn/single/remove",
          { recipient_id, currency }
        );
        console.log("resp", resp.data);
        toast({
          title: "Success",
          description: resp.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        window.location.reload();
        // setIsFetching(false);
      } catch (error) {
        console.log(error);
        //   setIsFetching(false);
        handleResponseError(error, toast);
      }
    }
  }

  async function removeRecipientInternal(
    recipient_id: string,
    currency: string
  ) {
    // setIsFetching(true);
    if (window.confirm("Are you sure")) {
      try {
        const resp = await httpRaw(
          "https://api.lucrestack.co/wallet/transfer/recipient/internal/ngn/single/remove",
          { recipient_id }
        );
        console.log("resp", resp.data);
        toast({
          title: "Success",
          description: resp.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        window.location.reload();
        // setIsFetching(false);
      } catch (error) {
        console.log(error);
        //   setIsFetching(false);
        handleResponseError(error, toast);
      }
    }
  }

  useEffect(() => {
    fetchServicesInternal();
    fetchServicesExternal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredExternal = useMemo(() => {
    if (!recipientsExternal || recipientsExternal.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? recipientsExternal
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
            )
        : sort === "date-desc"
        ? recipientsExternal
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
            )
        : sort === "z-a"
        ? recipientsExternal
            .slice()
            .sort((a: any, b: any) => b.name.localeCompare(a.name))
        : recipientsExternal
            .slice()
            .sort((a: any, b: any) => a.name.localeCompare(b.name));

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            e.name.toLowerCase().includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, recipientsExternal]);

  const filteredInternal = useMemo(() => {
    if (!recipientsInternal || recipientsInternal.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? recipientsInternal
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
            )
        : sort === "date-desc"
        ? recipientsInternal
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
            )
        : sort === "z-a"
        ? recipientsInternal
            .slice()
            .sort((a: any, b: any) => b.name.localeCompare(a.name))
        : recipientsInternal
            .slice()
            .sort((a: any, b: any) => a.name.localeCompare(b.name));

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            e.name.toLowerCase().includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, recipientsInternal]);

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/overview");
            }}
          >
            Business
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/wallet/details");
            }}
          >
            Wallet
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/wallet/transfer_recipients");
            }}
          >
            Recipients
          </div>
        </div>
      </div>

      <div className="mb-10 text-xl">Wallet Transfer Recipient</div>

      <div className="flex items-center gap-5 mb-8">
        <input
          value={search}
          type="text"
          className="px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Enter Search Keyword"
        />
        <div className="flex-1">
          <div className="text-xs">Sort by</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className="mb-6 border-slate-300 dark:border-neutral-700" />

      <div className="flex items-center justify-between mb-8">
        <div className="flex gap-5">
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "external",
              "": tab === "internal",
            })}
            onClick={() => {
              setTab("external");
            }}
          >
            External
          </button>
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "internal",
              "": tab === "internal",
            })}
            onClick={() => {
              setTab("internal");
            }}
          >
            Internal
          </button>
        </div>
        <AddTransferRecipientModal tab={tab}>
          <div className="flex gap-3 px-2 py-2 bg-purple-800 text-neutral-100">
            <FiUserPlus />
            <div className="text-xs">Add recipient</div>
          </div>
        </AddTransferRecipientModal>
      </div>

      {tab === "external" ? (
        <div className="grid mb-10">
          <FilterForm
            url="https://api.lucrestack.co/wallet/transfer/external/ngn/recipient/details"
            inputs={filterInputsExternal}
            paginationInput={paginationInputExternal}
            isLoading={isFetching}
            setIsLoading={setIsFetching}
            setState={setRecipientsExternal}
            setPaginationInput={setPaginationInputExternal}
          />

          <div className="relative overflow-auto max-h-[500px] flex-1">
            <table className="min-w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bank
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isFetching
                  ? filteredExternal.map((d, k) => (
                      <tr
                        key={k}
                        className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                      >
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {d.name}
                        </td>
                        <td className="px-6 py-4">{d.bank}</td>
                        <td className="px-6 py-4">{d.number}</td>
                        <td className="flex gap-3 px-6 py-4">
                          <AutoGenNormalViewModal title="Recipient" data={d}>
                            <span className="font-bold text-blue-600">
                              View
                            </span>
                          </AutoGenNormalViewModal>
                          <button
                            className="font-bold text-blue-600"
                            onClick={() =>
                              removeRecipientExtrernal(d.recipient_id, "ngn")
                            }
                          >
                            Remove recipient
                          </button>
                        </td>
                      </tr>
                    ))
                  : Array.from(Array(5)).map((k, i) => (
                      <tr
                        key={i}
                        className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                      >
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Skeleton h={"20px"} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton h={"20px"} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton h={"20px"} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton h={"20px"} />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="grid mb-10">
          <FilterForm
            url="https://api.lucrestack.co/wallet/transfer/internal/ngn/recipient/details"
            inputs={filterInputsInternal}
            paginationInput={paginationInputInternal}
            isLoading={isFetching}
            setIsLoading={setIsFetching}
            setState={setRecipientsInternal}
            setPaginationInput={setPaginationInputInternal}
          />

          <div className="relative overflow-auto max-h-[500px] flex-1">
            <table className="min-w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bank
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Number
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isFetching
                  ? filteredInternal.map((d, k) => (
                      <tr
                        key={k}
                        className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                      >
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {d.name}
                        </td>
                        <td className="px-6 py-4">{d.bank}</td>
                        <td className="px-6 py-4">{d.number}</td>
                        <td className="flex gap-3 px-6 py-4">
                          <AutoGenNormalViewModal title="Recipient" data={d}>
                            <span className="font-bold text-blue-600">
                              View
                            </span>
                          </AutoGenNormalViewModal>
                          <button
                            className="font-bold text-blue-600"
                            onClick={() =>
                              removeRecipientInternal(d.receiver_id, "ngn")
                            }
                          >
                            Remove recipient
                          </button>
                        </td>
                      </tr>
                    ))
                  : Array.from(Array(5)).map((k, i) => (
                      <tr
                        key={i}
                        className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                      >
                        <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Skeleton h={"20px"} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton h={"20px"} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton h={"20px"} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton h={"20px"} />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default BusinessWalletTransferRecipientClient;
