"use client";

import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import http from "@/lib/http";
import { Skeleton, useToast } from "@chakra-ui/react";
import classNames from "classnames";
import { BadRequestError, handleResponseError } from "@/utils/errors";
import { useNavigate, useParams } from "react-router-dom";
import { IUserDetails, IUserWallet } from "@/types/user.type";
import UserTopupWalletModal from "@/components/modals/UserTopupWalletModal";
import { FaLongArrowAltRight } from "react-icons/fa";
import UserDebitWalletModal from "@/components/modals/UserDebitWalletModal";
import UserWalletUpdateModal from "@/components/modals/UserWalletUpdateModal";
import { currencyFormat, spreadArrToString } from "@/utils/helpers";
import { TbCurrencyNaira } from "react-icons/tb";
import UpdateUserModal from "@/components/modals/UpdateUserModal";
import { FaRegEdit } from "react-icons/fa";
import { GrDocumentText } from "react-icons/gr";
import UserUpdateKycModal from "@/components/modals/UserUpdateKycModal";
import UserWalletTransactionModal from "@/components/modals/UserWalletTransactionModal";
import AutoGenNormalViewModal from "@/components/modals/AutoGenNormalViewModal";
import AddCommissionModal from "@/components/modals/AddCommissionModal";
import UpdateBusinessCommissionModal from "@/components/modals/UpdateBusinessCommissionModal";

function BusinessUsersClientDetails() {
  const params = useParams();
  const [user, setUser] = useState<IUserDetails | null>(null);
  const [userWallets, setUserWallets] = useState<IUserWallet[]>([]);
  const [commissionDetails, setCommissionDetails] = useState<any>(null);
  const [commisionPayments, setCommisionPayments] = useState<any[]>([]);
  const toast = useToast();
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(true);
  const [tab, setTab] = useState<"details" | "wallet" | "kyc" | "commission">(
    "details"
  );

  async function fetchUser() {
    try {
      const resp = await Promise.all([
        http("user/details", { user_id: params.id }),
        http("wallet/details", { user_id: params.id }),
        http("commission/details", { user_id: params.id }),
        http("commission/payments", { user_id: params.id }),
      ]);
      // console.log("account", resp)
      if (resp[0].data.length > 0) {
        setUser(resp[0].data[0]);
        setUserWallets(resp[1].data);
        if (resp[2].data.length > 0) {
          setCommissionDetails(resp[2].data[0]);
        }
        setCommisionPayments(resp[3].data);
        setIsFetching(false);
      } else {
        throw new BadRequestError("invalid user ID");
      }
    } catch (error) {
      console.log(error);
      handleResponseError(error, toast);
      navigate("/d/users");
    }
  }

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function walletDebit(wallet_id: string, amount: string) {
    const index = userWallets.findIndex(
      (wallet) => wallet.wallet_id === wallet_id
    );
    if (index !== -1) {
      const updatedWallet = [...userWallets];
      updatedWallet[index].available_balance = `${
        parseFloat(updatedWallet[index].available_balance) - parseFloat(amount)
      }`;
      setUserWallets(updatedWallet);
    }
  }

  function walletUpdate(
    wallet_id: string,
    active: string,
    locked_balance: string
  ) {
    const index = userWallets.findIndex(
      (wallet) => wallet.wallet_id === wallet_id
    );
    if (index !== -1) {
      const updatedWallet = [...userWallets];
      updatedWallet[index].locked_balance = locked_balance;
      // updatedWallet[index].active = active;
      setUserWallets(updatedWallet);
    }
  }

  function updateUser(
    user_id: string,
    email: string,
    phone: string,
    status: string,
    accountType: string
  ) {
    if (!user) return false;
    const updatedUser = { ...user };
    updatedUser.details.email = email;
    updatedUser.details.phone = phone;
    updatedUser.details.kyc.current_account_type = accountType;
    updatedUser.details.account_type = accountType;
    updatedUser.details.status = status;
    setUser(updatedUser);
  }

  function updateKyc(parameter: string, status: string) {
    if (!user) return false;
    const index = user.details.kyc.details.findIndex(
      (kyc) => kyc.parameter === parameter
    );
    if (index !== -1) {
      const updatedKycDetails = [...user.details.kyc.details];
      updatedKycDetails[index].status = status;
      // setUser((u) => ({...u, details: {...u.details, kyc: {...u.details.kyc, details: updatedKycDetails}}}));
    }
  }

  function updateCommission(
    user_id: string,
    newActive: string,
    rate: string,
    rateCapped: string
  ) {
    const updatedCommission = { ...commissionDetails };
    updatedCommission.active = newActive;
    updatedCommission.rate = rate;
    updatedCommission.rate_capped = rateCapped;
    setCommissionDetails(updatedCommission);
  }

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div>Business</div>
          <div>
            <FaAngleRight />
          </div>
          <div>User</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Details</div>
        </div>
      </div>

      <div className="mb-8 text-xl">User Details</div>
      <hr className="mb-8 border-slate-300 dark:border-neutral-700" />

      <div className="w-full mb-10">
        <div className="flex items-center justify-between mb-10">
          <div className="flex items-center gap-3">
            <div>
              {user && !isFetching && user.details.photo.length > 0 ? (
                <div
                  className="bg-no-repeat bg-cover bg-center w-[60px] h-[60px] rounded-full"
                  style={{ backgroundImage: `url(${user.details.photo})` }}
                ></div>
              ) : (
                <div className="p-4 bg-purple-800 rounded-full">
                  <FcBusinessman className="w-7 h-7" />
                </div>
              )}
            </div>
            <div className="text-sm font-bold">
              {user && !isFetching ? (
                <span>
                  {user.details.firstname} {user.details.middlename}{" "}
                  {user.details.lastname}
                </span>
              ) : (
                <Skeleton w={"100px"} h={"20px"} />
              )}
            </div>
          </div>
        </div>

        <div className="flex gap-5 mb-8">
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "details",
              "": tab === "wallet",
            })}
            onClick={() => {
              setTab("details");
            }}
          >
            Details
          </button>
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "wallet",
              "": tab === "wallet",
            })}
            onClick={() => {
              setTab("wallet");
            }}
          >
            Wallets
          </button>
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "kyc",
              "": tab === "wallet",
            })}
            onClick={() => {
              setTab("kyc");
            }}
          >
            KYC
          </button>
          <button
            className={classNames("text-[13px]", {
              "text-purple-600 underline font-bold": tab === "commission",
              "": tab === "wallet",
            })}
            onClick={() => {
              setTab("commission");
            }}
          >
            Commission
          </button>
        </div>

        {tab === "details" ? (
          <>
            <div className="mb-14">
              <div className="flex items-center justify-between mb-3 text-sm font-bold">
                <div className="border-b-2 border-purple-800 pb-[3px]">
                  User information
                </div>
                <div className="">
                  {user && (
                    <UpdateUserModal updateUser={updateUser} data={user}>
                      <div className="flex items-center gap-2 px-2 py-2 bg-purple-800 text-neutral-100">
                        <FaRegEdit />
                        <div className="text-xs">Update user</div>
                      </div>
                    </UpdateUserModal>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    First name
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.firstname}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Middle name
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.middlename}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Last name
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.lastname}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    User name
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.username}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Email
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.email}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Phone
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.phone}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Gender
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.gender}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Date of birth
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.dob}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Status
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.status}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Referral code
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.referral_code}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Merchant name
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.merchant_name}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Active
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.active}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Created on
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.created_on}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-10">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                Account officer
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Firstname
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.account_officer.firstname}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Lastname
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.account_officer.lastname}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Middlename
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.account_officer.middlename}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : tab === "kyc" ? (
          <>
            <div className="mb-14">
              <div className="flex items-center justify-between mb-3 text-sm font-bold">
                <div className="border-b-2 border-purple-800 pb-[3px]">
                  Basic information
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Current account type
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.kyc.current_account_type}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Email verified
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.kyc.auth.email_verified}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Phone verified
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.kyc.auth.phone_verified}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Current kyc class
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.kyc.current_kyc_class}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Expected track
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.kyc.expected_track}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Current track
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.kyc.current_track}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Remaining track
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>{user.details.kyc.remaining_track}</span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                    Available account type
                  </div>
                  <div className="text-sm">
                    {user && !isFetching ? (
                      <span>
                        {spreadArrToString(
                          user.details.kyc.available_account_type
                        )}
                      </span>
                    ) : (
                      <Skeleton w={"100px"} h={"20px"} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-10">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                KYC Details
              </div>

              <div className="relative overflow-auto max-h-[500px]">
                <table className="w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
                  <thead className="text-[11px] text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        KYC Parameter
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Type
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Class
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Option
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Created on
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Updated on
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetching && user
                      ? user.details.kyc.details.map((d, k) => (
                          <tr
                            key={k}
                            className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="flex gap-3 px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <GrDocumentText />
                              <div>{d.parameter}</div>
                            </th>
                            <td className="px-6 py-4">{d.status}</td>
                            <td className="px-6 py-4">{d.type}</td>
                            <td className="px-6 py-4">{d.class}</td>
                            <td className="px-6 py-4">{d.option}</td>
                            <td className="px-6 py-4">{d.created_on}</td>
                            <td className="px-6 py-4">{d.updated_on}</td>
                            <td className="flex gap-3 px-6 py-4">
                              <UserUpdateKycModal
                                data={d}
                                updateKyc={updateKyc}
                                id={user.details.user_id}
                              >
                                <span className="font-bold text-blue-600">
                                  Update
                                </span>
                              </UserUpdateKycModal>
                            </td>
                          </tr>
                        ))
                      : Array.from(Array(5)).map((k, i) => (
                          <tr
                            key={i}
                            className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                          >
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : tab === "commission" ? (
          <>
            <div className="mb-10">
              <div className="flex items-center justify-between mb-3 text-sm font-bold">
                <div className="border-b-2 border-purple-800 pb-[3px]">
                  Commission
                </div>
                <div className="text-purple-600">
                  {user && (
                    <div className="flex items-center gap-2">
                      {commissionDetails ? (
                        <UpdateBusinessCommissionModal
                          user_id={user.details.user_id}
                          data={commissionDetails}
                          updateCommission={updateCommission}
                        >
                          <div className="px-2 py-2 text-xs bg-purple-800 text-neutral-100">
                            Update commission
                          </div>
                        </UpdateBusinessCommissionModal>
                      ) : (
                        <AddCommissionModal
                          user_id={user.details.user_id}
                          setCommissionDetails={setCommissionDetails}
                        >
                          <div className="px-2 py-2 text-xs bg-purple-800 text-neutral-100">
                            Add commission
                          </div>
                        </AddCommissionModal>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div>
                {commissionDetails ? (
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                    <div>
                      <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                        Rate
                      </div>
                      <div className="text-sm">
                        {user && !isFetching ? (
                          <span>{commissionDetails.rate}</span>
                        ) : (
                          <Skeleton w={"100px"} h={"20px"} />
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                        Rate type
                      </div>
                      <div className="text-sm">
                        {user && !isFetching ? (
                          <span>{commissionDetails.rate_type}</span>
                        ) : (
                          <Skeleton w={"100px"} h={"20px"} />
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                        Rate capped
                      </div>
                      <div className="text-sm">
                        {user && !isFetching ? (
                          <span>{commissionDetails.rate_capped}</span>
                        ) : (
                          <Skeleton w={"100px"} h={"20px"} />
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm font-bold text-slate-500 dark:text-neutral-500">
                        Active
                      </div>
                      <div className="text-sm">
                        {user && !isFetching ? (
                          <span>{commissionDetails.active}</span>
                        ) : (
                          <Skeleton w={"100px"} h={"20px"} />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[130px]">
                    <div>No commission added yet</div>
                  </div>
                )}
              </div>
            </div>

            <div className="mb-10">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-3 text-sm font-bold">
                Commission payments
              </div>

              <div className="relative overflow-auto max-h-[500px]">
                <table className="w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
                  <thead className="text-[11px] text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        reference code
                      </th>
                      <th scope="col" className="px-6 py-3">
                        amount
                      </th>
                      <th scope="col" className="px-6 py-3">
                        timestamp
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetching && user
                      ? commisionPayments.map((d, k) => (
                          <tr
                            key={k}
                            className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                          >
                            <th scope="row" className="px-6 py-4">
                              <div>{d.reference_code}</div>
                            </th>
                            <td className="px-6 py-4">{d.reference_code}</td>
                            <td className="px-6 py-4">{d.timestamp}</td>
                          </tr>
                        ))
                      : Array.from(Array(5)).map((k, i) => (
                          <tr
                            key={i}
                            className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                          >
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="mb-10">
                <div className="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-2 sm:gap-6">
                  {!isFetching
                    ? userWallets.map((w, i) => (
                        <div
                          key={i}
                          className="px-3 py-4 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800"
                        >
                          <div className="flex items-start gap-3">
                            <div className="p-1 bg-green-700 text-neutral-100 sm:p-3">
                              <TbCurrencyNaira className="w-7 h-7" />
                            </div>
                            <div className="w-full">
                              {/* <div className="mb-3">
                                <div className="flex gap-1 text-xs">
                                  <div>Type:</div>
                                  <div className="text-orange-600 underline">
                                    {w.type}
                                  </div>
                                </div>
                              </div> */}
                              <div className="mb-3">
                                <div className="text-[10px]">
                                  Available balance
                                </div>
                                <div>{currencyFormat(w.available_balance)}</div>
                              </div>
                              <div className="mb-3">
                                <div className="text-[10px]">
                                  Pending balance
                                </div>
                                <div>{currencyFormat(w.pending_balance)}</div>
                              </div>
                              <div className="mb-3">
                                <div className="text-[10px]">
                                  Locked balance
                                </div>
                                <div>{currencyFormat(w.locked_balance)}</div>
                              </div>
                              <div className="flex gap-5 mb-8">
                                <div>
                                  <div className="text-[10px]">
                                    Total inflow
                                  </div>
                                  <div>{w.inflow.total}</div>
                                </div>
                                <div>
                                  <div className="text-[10px]">
                                    Total outflow
                                  </div>
                                  <div>{w.outflow.total}</div>
                                </div>
                              </div>
                              <div className="">
                                <div className="text-xl text-opacity-80 "></div>
                                <div className="flex gap-2 mb-3 font-bold">
                                  <UserTopupWalletModal data={w}>
                                    <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                                      <FaLongArrowAltRight className="w-2 h-2" />
                                      <div>Fund</div>
                                    </div>
                                  </UserTopupWalletModal>
                                  <UserDebitWalletModal
                                    data={w}
                                    walletDebit={walletDebit}
                                  >
                                    <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                                      <FaLongArrowAltRight className="w-2 h-2" />
                                      <div>Debit</div>
                                    </div>
                                  </UserDebitWalletModal>
                                  <UserWalletUpdateModal
                                    data={w}
                                    walletUpdate={walletUpdate}
                                  >
                                    <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                                      <FaLongArrowAltRight className="w-2 h-2" />
                                      <div>Update</div>
                                    </div>
                                  </UserWalletUpdateModal>
                                  {/* <UserTopupWalletModal data={w} walletTopup={walletTopup}>
                                                    <div className='flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer'>
                                                        <FaLongArrowAltRight className='w-2 h-2' />
                                                        <div>Withdraw earnings</div>
                                                    </div>
                                                </UserTopupWalletModal> */}
                                </div>
                                <div className="flex gap-2 font-bold">
                                  <AutoGenNormalViewModal
                                    title="Virtual Accounts"
                                    data={w.virtual_funding_account}
                                  >
                                    <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                                      <FaLongArrowAltRight className="w-2 h-2" />
                                      <div>View virtual accounts</div>
                                    </div>
                                  </AutoGenNormalViewModal>

                                  {/* <UserWalletTransactionModal data={w}>
                                    <div className="flex items-center gap-1 text-[11px] md:text-[12px] text-neutral-700 dark:text-neutral-300 underline cursor-pointer">
                                      <FaLongArrowAltRight className="w-2 h-2" />
                                      <div>Transactions</div>
                                    </div>
                                  </UserWalletTransactionModal> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : Array.from(Array(6)).map((k) => (
                        <Skeleton h={"130px"} key={k} />
                      ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default BusinessUsersClientDetails;
