"use client";

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import http from "@/lib/http";
import { handleResponseError } from "@/utils/errors";
import { Form } from "antd";

function AddProductManagerModal({
  children,
  data,
  reRunFetch,
}: {
  children: React.ReactNode;
  data: any;
  reRunFetch: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();
  const [form] = Form.useForm();

  const [email, setEmail] = useState<string>("");
  const [isFetching, setIsFetching] = useState(false);

  async function addManager() {
    setIsFetching(true);
    try {
      const resp = await http("services/addon/payments/manager/add", {
        user_id: email,
        id: data?.id,
      });

      // console.log("resp", resp.data)

      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      reRunFetch();
      onClose();
      setIsFetching(false);
    } catch (error: any) {
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Add user
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="mb-3">
              <Form onFinish={addManager} form={form}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Email or Phone Number is required",
                    },
                  ]}
                >
                  <div className="">
                    <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                      Email or Phone Number
                    </div>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                      disabled={isFetching}
                    />
                  </div>
                </Form.Item>

                <Form.Item>
                  <button
                    className="flex justify-center px-10 py-2 mt-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                    // onClick={() => addManager()}
                    disabled={isFetching}
                    type="submit"
                  >
                    {isFetching ? (
                      <ImSpinner8 className="w-5 h-5 animate-spin" />
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </Form.Item>
              </Form>
            </div>
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddProductManagerModal;
