import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayoutClient from "./components/AuthLayoutClient";
import LoginClient from "./pages/auth/LoginClient";
import LoginOTPAuthenticateAccountClient from "./pages/auth/LoginOTPAuthenticateAccountClient";
import KycClient from "./pages/businesses/KycClient";
import NotificationClient from "./pages/businesses/NotificationClient";
import BusinessLayoutClient from "./components/BusinessLayoutClient";
import BusinessOverviewClient from "./pages/businesses/BusinessOverviewClient";
import BusinessAccountDocumentClient from "./pages/businesses/BusinessAccountDocumentClient";
import BusinessServicesDetailsClient from "./pages/businesses/BusinessServicesDetailsClient";
import BusinessServicesTransactionsClient from "./pages/businesses/BusinessServicesTransactionsClient";
import BusinessSubscriptionPaymentsClient from "./pages/businesses/BusinessSubscriptionPaymentsClient";
import BusinessTeamClient from "./pages/businesses/BusinessTeamClient";
import BusinessUsersClient from "./pages/businesses/BusinessUsersClient";
import BusinessWalletClient from "./pages/businesses/BusinessWalletClient";
import BusinessWalletTransactionsClient from "./pages/businesses/BusinessWalletTransactionsClient";
import RetrieveAccountCient from "./pages/auth/RetrieveAccountClient";
import BusinessServicesLoanDetailsClient from "./pages/businesses/BusinessServicesLoanDetailsClient";
import BusinessServicesLoanRequestsClient from "./pages/businesses/BusinessServicesLoanRequestsClient";
import AccountDetailsClient from "./pages/account/AccountDetailsClient";
import BusinessWalletTransferRecipientClient from "./pages/businesses/BusinessWalletTransferRecipientClient";
import BusinessServicesLoanCreateClient from "./pages/businesses/BusinessServicesLoanCreateClient";
import BusinessServicesLoanUpdateClient from "./pages/businesses/BusinessServicesLoanUpdateClient";
import BusinessUsersClientList from "./pages/businesses/BusinessUsersClientList";
import BusinessUsersClientDetails from "./pages/businesses/BusinessUsersClientDetails";
import BusinessAccountDetailsClient from "./pages/businesses/BusinessAccountDetailsClient";
import BusinessCardsClient from "./pages/businesses/BusinessCardsClient";
import BusinessServicesBankAccountClient from "./pages/businesses/BusinessServicesBankAccountClient ";
import BusinessServicesInvestmentDetailsClient from "./pages/businesses/BusinessServicesInvestmentDetailsClient";
import BusinessServicesInvestmentCreateClient from "./pages/businesses/BusinessServicesInvestmentCreateClient";
import BusinessServicesInvestmentUpdateClient from "./pages/businesses/BusinessServicesInvestmentUpdateClient";
import BusinessServicesInvestmentRequestsClient from "./pages/businesses/BusinessServicesInvestmentRequestsClient";
import BusinessServicesSavingsDetailsClient from "./pages/businesses/BusinessServicesSavingsDetailsClient";
import BusinessServicesSavingsCreateClient from "./pages/businesses/BusinessServicesSavingsCreateClient";
import BusinessServicesSavingsUpdateClient from "./pages/businesses/BusinessServicesSavingsUpdateClient";
import BusinessServicesSavingsRequestsClient from "./pages/businesses/BusinessServicesSavingsRequestsClient";
import BusinessServicesPaymentsDetailsClient from "./pages/businesses/BusinessServicesPaymentsDetailsClient";
import BusinessServicesPaymentsCreateClient from "./pages/businesses/BusinessServicesPaymentsCreateClient";
import BusinessServicesPaymentsUpdateClient from "./pages/businesses/BusinessServicesPaymentsUpdateClient";
import BusinessServicesPaymentsRequestsClient from "./pages/businesses/BusinessServicesPaymentsRequestsClient";

function App() {
  return (
    <Routes>
      <Route path="" element={<Navigate to={"/d/overview"} />}></Route>
      <Route element={<AuthLayoutClient />}>
        <Route path="/auth/login" element={<LoginClient />}></Route>
        <Route
          path="/auth/login/authenticate"
          element={<LoginOTPAuthenticateAccountClient />}
        ></Route>
        <Route
          path="/auth/retrieve-account"
          element={<RetrieveAccountCient />}
        ></Route>
      </Route>
      <Route path="d" element={<BusinessLayoutClient />}>
        <Route path="overview" element={<BusinessOverviewClient />}></Route>
        <Route
          path="account/details"
          element={<AccountDetailsClient />}
        ></Route>
        <Route
          path="business/documents"
          element={<BusinessAccountDocumentClient />}
        ></Route>
        <Route
          path="business/details"
          element={<BusinessAccountDetailsClient />}
        ></Route>
        <Route
          path="services/details"
          element={<BusinessServicesDetailsClient />}
        ></Route>
        <Route
          path="services/bankaccount"
          element={<BusinessServicesBankAccountClient />}
        ></Route>
        <Route
          path="transactions"
          element={<BusinessServicesTransactionsClient />}
        ></Route>
        <Route path="services/cards" element={<BusinessCardsClient />}></Route>
        <Route
          path="services/investment"
          element={<BusinessServicesInvestmentDetailsClient />}
        ></Route>
        <Route
          path="services/investment/create"
          element={<BusinessServicesInvestmentCreateClient />}
        ></Route>
        <Route
          path="services/investment/update/:id"
          element={<BusinessServicesInvestmentUpdateClient />}
        ></Route>
        <Route
          path="services/investment/requests"
          element={<BusinessServicesInvestmentRequestsClient />}
        ></Route>
        <Route
          path="services/savings"
          element={<BusinessServicesSavingsDetailsClient />}
        ></Route>
        <Route
          path="services/savings/create"
          element={<BusinessServicesSavingsCreateClient />}
        ></Route>
        <Route
          path="services/savings/update/:id"
          element={<BusinessServicesSavingsUpdateClient />}
        ></Route>
        <Route
          path="services/savings/requests"
          element={<BusinessServicesSavingsRequestsClient />}
        ></Route>
        <Route
          path="services/loan"
          element={<BusinessServicesLoanDetailsClient />}
        ></Route>
        <Route
          path="services/loan/create"
          element={<BusinessServicesLoanCreateClient />}
        ></Route>
        <Route
          path="services/loan/update/:id"
          element={<BusinessServicesLoanUpdateClient />}
        ></Route>
        <Route
          path="services/loan/requests"
          element={<BusinessServicesLoanRequestsClient />}
        ></Route>
        <Route
          path="services/payments"
          element={<BusinessServicesPaymentsDetailsClient />}
        ></Route>
        <Route
          path="services/payments/create"
          element={<BusinessServicesPaymentsCreateClient />}
        ></Route>
        <Route
          path="services/payments/update/:id"
          element={<BusinessServicesPaymentsUpdateClient />}
        ></Route>
        <Route
          path="services/payments/requests"
          element={<BusinessServicesPaymentsRequestsClient />}
        ></Route>
        <Route
          path="subscription/payments"
          element={<BusinessSubscriptionPaymentsClient />}
        ></Route>
        <Route path="team" element={<BusinessTeamClient />}></Route>
        <Route path="users" element={<BusinessUsersClient />}></Route>
        <Route path="users/list" element={<BusinessUsersClientList />}></Route>
        <Route
          path="users/details/:id"
          element={<BusinessUsersClientDetails />}
        ></Route>
        <Route path="wallet/details" element={<BusinessWalletClient />}></Route>
        <Route
          path="wallet/transactions"
          element={<BusinessWalletTransactionsClient />}
        ></Route>
        <Route
          path="wallet/transfer_recipients"
          element={<BusinessWalletTransferRecipientClient />}
        ></Route>
        <Route path="kyc" element={<KycClient />}></Route>
        <Route path="notification" element={<NotificationClient />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
