"use client";

import FilterForm from "@/components/FilterForm";
import UpdateUserModal from "@/components/modals/UpdateUserModal";
import http from "@/lib/http";
import { Skeleton } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { FcBusinessman } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

function BusinessUsersClientList() {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [users, setUsers] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    user_id: "",
    email: "",
    phone: "",
    account_type: "",
    status: "",
    date_filter_from: "",
    date_filter_to: "",
    order_by: "",
  };
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchTeams() {
    try {
      const resp = await http("user/details", { limit: 6 });
      // console.log("resp", resp.data)
      setUsers(resp.data);
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtered = useMemo(() => {
    if (users.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? users
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.details.created_on).getTime() -
                new Date(b.details.created_on).getTime()
            )
        : sort === "date-desc"
        ? users
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.details.created_on).getTime() -
                new Date(a.details.created_on).getTime()
            )
        : sort === "z-a"
        ? users
            .slice()
            .sort((a: any, b: any) =>
              b.details.firstname.localeCompare(a.details.firstname)
            )
        : users
            .slice()
            .sort((a: any, b: any) =>
              a.details.firstname.localeCompare(b.details.firstname)
            );

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            `${e.details.firstname} ${e.details.lastname}`
              .toLowerCase()
              .includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, users]);

  function updateUser(
    user_id: string,
    email: string,
    phone: string,
    status: string,
    accountType: string
  ) {
    const index = users.findIndex((user) => user.details.user_id === user_id);
    if (index !== -1) {
      const updatedUsers = [...users];
      updatedUsers[index].details.email = email;
      updatedUsers[index].details.phone = phone;
      updatedUsers[index].details.account = accountType;
      updatedUsers[index].details.status = status;
      setUsers(updatedUsers);
    }
  }

  return (
    <>
      <div className="flex items-center gap-3 mb-10 text-sm text-slate-500">
        <div>Business</div>
        <div>
          <FaAngleRight />
        </div>
        <div>Team</div>
      </div>

      <div className="mb-10 text-xl">Users</div>

      <div className="flex items-center gap-5 mb-8">
        <input
          value={search}
          type="text"
          className="px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Enter Search Keyword"
        />
        <div className="flex-1">
          <div className="text-xs">Sort by</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className="mb-10 border-slate-300 dark:border-neutral-700" />

      <FilterForm
        url="user/details"
        inputs={filterInputs}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setUsers}
        setPaginationInput={setPaginationInput}
        limit={6}
        inputtedIsSelectTag={{
          status: ["Pending", "Approved", "Banned"],
          account_type: ["Starter", "Rated", "Standard", "Merchant"],
        }}
      />

      <div className="mb-10 overflow-auto max-h-[500px]">
        <div className="grid grid-cols-1 gap-3 md:grid-cols-3 sm:gap-6">
          {!isFetching
            ? filtered.map((t, i) => (
                <div
                  key={i}
                  className="flex items-center gap-3 p-5 mb-5 border-2 border-neutral-300 dark:border-neutral-700"
                >
                  {/* <div className='w-[50px] h-[40px] overflow-hidden rounded-full hidden md:inline-block'>
                                <Image src={'/dummy-profile/image1.avif'} className='w-[45px] rounded-full' alt='photo' width={50} height={50} />
                              </div> */}
                  <div className="flex justify-center px-2 py-2 rounded-full">
                    <FcBusinessman className="w-7 h-7" />
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="text-xs">
                      <div className="font-bold">{`${t.details.firstname} ${t.details.lastname}`}</div>
                      <div className="">{t.details.email}</div>
                      <div className="">{t.details.phone}</div>
                      <div>Status: {t.details.status}</div>
                      {/* <div>Role: {t.details.role ?? "-"}</div> */}
                      <div className="mb-5">Joined: {t.details.created_on}</div>
                      <div className="">
                        <div className="flex items-center gap-3 text-[10px]">
                          <div
                            className="flex items-center gap-1 font-bold cursor-pointer"
                            onClick={() =>
                              navigate(`/d/users/details/${t.details.user_id}`)
                            }
                          >
                            <FaLongArrowAltRight className="w-2 h-2" />
                            <div>View full info</div>
                          </div>
                          <UpdateUserModal updateUser={updateUser} data={t}>
                            <div className="flex items-center gap-1 font-bold">
                              <FaLongArrowAltRight className="w-2 h-2" />
                              <div>Update</div>
                            </div>
                          </UpdateUserModal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : Array.from(Array(8)).map((k) => <Skeleton h={"130px"} key={k} />)}
        </div>
      </div>
    </>
  );
}

export default BusinessUsersClientList;
