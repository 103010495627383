import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import http from "@/lib/http";
import AddProductManagerModal from "./AddProductManagerModal";
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";

const ViewManageProductModal = ({
  children,
  data,
  title,
}: {
  title: string;
  data: any;
  children: React.ReactNode;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [deleting, setDeleting] = useState(false);
  const [currentDetail, setCurrentDetail] = useState<{
    primary_priviledge: string;
    requester: {
      id: string;
      lastname: string;
      middlename: string;
      firstname: string;
      merchant_name: string;
      email: string;
      phone: string;
    };
  } | null>(null);
  const [details, setDetails] = useState<
    {
      primary_priviledge: string;
      requester: {
        id: string;
        lastname: string;
        middlename: string;
        firstname: string;
        merchant_name: string;
        email: string;
        phone: string;
      };
    }[]
  >([]);
  const [isFetching, setIsFetching] = useState(true);
  const [paginationInput, setPaginationInput] = useState<any>();

  const fetchDetails = async () => {
    try {
      const resp = await http("services/addon/payments/manager/list", {
        limit: 10,
        id: data?.id,
      });
      // console.log("resp", resp)
      setDetails(resp.data);
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function deleteManager(d: {
    primary_priviledge: string;
    requester: {
      id: string;
      lastname: string;
      middlename: string;
      firstname: string;
      merchant_name: string;
      email: string;
      phone: string;
    };
  }) {
    setDeleting(true);
    try {
      const resp = await http("services/addon/payments/manager/remove", {
        user_id: d?.requester?.email?.trim()?.length
          ? d?.requester?.email
          : d?.requester?.phone,
        id: data?.id,
      });

      // console.log("resp", resp.data)

      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setDeleting(false);
      fetchDetails();
    } catch (error: any) {
      setDeleting(false);
      handleResponseError(error, toast);
    }
  }

  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={"4xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            {title}
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />
          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div>
              <AddProductManagerModal
                data={data}
                reRunFetch={() => {
                  fetchDetails();
                }}
              >
                <div className="flex items-center justify-between mb-6">
                  <div className="flex gap-3 px-2 py-2 bg-purple-800 cursor-pointer text-neutral-100">
                    {/* <FiUserPlus /> */}
                    <div className="text-xs">Add User</div>
                  </div>
                </div>
              </AddProductManagerModal>
            </div>

            <div>
              <div className="relative overflow-auto max-h-[500px] flex-1">
                <table className="min-w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Phone
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isFetching
                      ? details?.map((d, k) => (
                          <tr
                            key={k}
                            className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {d.requester?.firstname} {d.requester?.lastname}
                            </th>
                            <td className="px-6 py-4">
                              {d.requester?.email?.trim().length
                                ? d.requester?.email
                                : "-"}
                            </td>
                            <td className="px-6 py-4">
                              {d.requester?.phone?.trim().length
                                ? d.requester?.phone
                                : "-"}
                            </td>
                            <td className="flex gap-3 px-6 py-4">
                              <p
                                className="font-bold text-blue-600 cursor-pointer"
                                onClick={() => {
                                  if (!deleting) {
                                    setCurrentDetail(d);
                                    deleteManager(d);
                                  }
                                }}
                              >
                                {deleting && currentDetail === d ? (
                                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                                ) : (
                                  <span>Delete</span>
                                )}
                              </p>
                            </td>
                          </tr>
                        ))
                      : Array.from(Array(5)).map((k, i) => (
                          <tr
                            key={i}
                            className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                          >
                            <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                            <td className="px-6 py-4">
                              <Skeleton h={"20px"} />
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ViewManageProductModal;
