'use client'

import http from '@/lib/http';
import React, { useState } from 'react'
import { ImSpinner8 } from "react-icons/im";
import { useToast } from '@chakra-ui/react'
import { handleResponseError } from '@/utils/errors';
import { useApp } from '@/contexts/appContext';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';

function LoginClient() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isFetching, setIsFetching] = useState(false);

    const { setAuthCred } = useApp();
    const navigate = useNavigate();
    const toast = useToast();

    const [passwordShow, setPasswordShow] = useState(false)

    async function signin() {
        setIsFetching(true);
        try {
            const resp = await http("account/login/initiate", { email, password }, process.env.REACT_APP_PUBLIC_KEY);
            setAuthCred({
                email: email,
                reference_code: resp.data.reference_code
            })
            console.log(resp)
            navigate('/auth/login/authenticate');
        } catch (error) {
            console.log(error);
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    return (
        <>
            <div className='flex justify-center h-full'>
                <div className='mt-[150px]'>
                    <div className='w-[300px] md:w-[400px] lg:w-[450px]px-6 py-5'>
                        <div className='font-bold mb-10 text-lg border-b-[5px] border-b-purple-800 inline-block'>Sign in</div>

                        <div className='mb-5'>
                            <div className='text-xs font-bold text-slate-600 dark:text-neutral-500 mb-[5px]'>Email</div>
                            <input type="email" name='email' className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setEmail(e.target.value)} placeholder='Enter email' disabled={isFetching} />
                        </div>

                        <div className='mb-8'>
                            <div className='text-xs font-bold text-slate-600 dark:text-neutral-500 mb-[5px]'>Password</div>
                            <div className='flex items-center'>
                                <input type={passwordShow ? "text" : "password"} name='password' className='px-3 py-3 text-xs w-full border-2 border-neutral-600 border-r-0 dark:bg-neutral-700 outline-none' onChange={(e) => setPassword(e.target.value)} placeholder='Enter password' disabled={isFetching} />
                                <div className='cursor-pointer border-2 text-md border-neutral-600 border-l-0 px-3 py-3 dark:bg-neutral-700' onClick={() => setPasswordShow((e) => e ? false : true)}>
                                    {
                                        !passwordShow ?
                                            <FiEye /> :
                                            <FiEyeOff />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='mb-3'>
                            <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 w-full disabled:bg-opacity-50 flex justify-center' onClick={() => signin()} disabled={isFetching}>
                                {
                                    isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                                }
                            </button>
                        </div>
                        <div className='text-xs flex gap-3'>
                            <div>Forgot password ?</div>
                            <Link to={'/auth/retrieve-account'} className='text-purple-800 font-bold'>Retrieve account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginClient