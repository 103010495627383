'use client'

import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { IInvestmentRequest } from '@/types/global.types';

function UpdateInvestmentRequestModal({
  children,
  data,
}: {
  children: React.ReactNode;
  data: IInvestmentRequest;
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [updateInvestmentRequestInput, setUpdateInvestmentRequestInput] = useState<{
    reference_code: string,
    remark: string,
    status: string
  }>({
    reference_code: data.reference_code,
    remark: data.remark,
    status: data.status
  });
  const [isFetching, setIsFetching] = useState(false);

  async function serviceUpdate() {
    setIsFetching(true);
    try {
      const resp = await http("services/addon/investment/request/update", { ...updateInvestmentRequestInput });
      // console.log("resp", resp.data)
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose();
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Update investment request</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <div className='mb-3'>
              <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>Reference code: {data.reference_code}</div>

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Remark</div>
                <input type='text' value={updateInvestmentRequestInput.remark} onChange={(e) => setUpdateInvestmentRequestInput({ ...updateInvestmentRequestInput, remark: e.target.value })} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching} />
              </div>              

              <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Status</div>
                <select value={updateInvestmentRequestInput.status} onChange={(e) => setUpdateInvestmentRequestInput({ ...updateInvestmentRequestInput, status: e.target.value })} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' disabled={isFetching}>
                  <option value="">Select an option</option>
                  <option value="Approved">Approved</option>
                  <option value="Failed">Failed</option>
                </select>
              </div>

              <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => serviceUpdate()} disabled={isFetching}>
                {
                  isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                }
              </button>
            </div>
          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdateInvestmentRequestModal