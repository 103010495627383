'use client'

import http from '@/lib/http';
import { BadRequestError, handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { FaAngleRight } from 'react-icons/fa6'
import { ImSpinner8 } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

function BusinessUsersClient() {

    const navigate = useNavigate();
    const toast = useToast();
    const [isFetching, setIsFetching] = useState(false);
    const [userId, setUserId] = useState("");

    async function fetchUser() {
        if (userId.length === 0) return false;
        setIsFetching(true);
        try {
            const resp = await http("user/details", { user_id: userId });
            // console.log("resp", resp.data)
            if (!resp.data || resp.data.length < 1) {
                throw new BadRequestError("Invalid user ID");
            }else {
                navigate(`/d/users/details/${userId}`);
            }            
        } catch (error) {
            console.log(error)
            setIsFetching(false);
            handleResponseError(error, toast);
        }
    }

    return (
        <>
            <div className='text-sm text-slate-500 flex items-center gap-3 mb-10'>
                <div>Business</div>
                <div><FaAngleRight /></div>
                <div>Users</div>
            </div>

            <div className='mb-3 flex justify-between items-center'>
                <div>
                    <div className='text-xl'>Browse User</div>
                    <div className='text-xs text-slate-400 dark:text-neutral-500'>manage user account.</div>
                </div>
                <div>
                    <button className='text-purple-600 text-sm cursor-pointer' onClick={() => { navigate('/d/users/list') }}>View all users</button>
                </div>
            </div>

            <hr className='mb-14 border-slate-300 dark:border-neutral-700' />

            <div className='mb-8 flex gap-5 justify-center items-center'>

                <div>
                    <div className='mb-5'>
                        <div className='text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]'>Enter user ID</div>
                        <input value={userId} type="number" className='px-3 py-3 sm:w-[400px] w-[250px] text-sm border-2 border-neutral-600 dark:bg-neutral-700' onChange={(e) => setUserId(e.target.value)} placeholder='Enter User ID' />
                    </div>

                    <button className='bg-purple-800 px-4 py-2 text-neutral-200 disabled:bg-opacity-50' onClick={() => fetchUser()} disabled={isFetching}>
                        {isFetching ? <div className='px-5'><ImSpinner8 className='animate-spin w-5 h-5' /></div> : <span>Submit</span>}
                    </button>
                </div>

            </div>


        </>
    )
}

export default BusinessUsersClient