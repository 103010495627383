'use client'

import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useTheme } from '@/contexts/themeProvider';
import classNames from 'classnames';
import { ImSpinner8 } from 'react-icons/im';
import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { currencyFormat } from '@/utils/helpers';
// import { FaHouseUser } from "react-icons/fa";

function UserDebitWalletModal({
  children,
  data,
  walletDebit
}: {
  children: React.ReactNode;
  data: any;
  walletDebit: (wallet_id: string, amount: string) => void
}) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [amount, setAmount] = useState<string>("");
  const [isFetching, setIsFetching] = useState(false);

  async function walletInternalTransfer() {
    setIsFetching(true);
    try {
      const resp = await http("wallet/transfer", { wallet_id: data.wallet_id, amount });
      // console.log("resp", resp.data);
      walletDebit(data.wallet_id, amount);
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className='inline-block cursor-pointer'>{children}</div>

      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen} size={{ base: 'sm', md: 'lg' }} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>Debit User Wallet</ModalHeader>
          <ModalCloseButton className={classNames('outline-none', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })} />

          <ModalBody className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>

            <div className='mb-3'>
                  <div className='inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold'>{currencyFormat(data.available_balance)}</div>

                  <div className='mb-5'>
                    <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>Amount</div>
                    <input type='text' value={amount} onChange={(e) => setAmount(e.target.value)} className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' placeholder='0.00' disabled={isFetching} />
                  </div>

                  <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 disabled:bg-opacity-50 flex justify-center' onClick={() => walletInternalTransfer()} disabled={isFetching}>
                    {
                      isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                    }
                  </button>
                </div>

          </ModalBody>

          <ModalFooter className={classNames('', {
            'bg-neutral-800 text-neutral-100': resolvedTheme === "dark",
            'bg-slate-50 text-neutral-800': resolvedTheme === "light",
          })}>
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UserDebitWalletModal