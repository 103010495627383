'use client'

import React, { useEffect, useState } from 'react'
import http from '@/lib/http';
import { BsSendCheck } from "react-icons/bs";
import { Skeleton } from '@chakra-ui/react';
import SendNotificationModal from '@/components/modals/SendNotificationModal';

function NotificationClient() {

  const [isFetching, setIsFetching] = useState(true);
  const [notification, setNotification] = useState<{
    header: string;
    body: string;
  } | null>(null);

  async function fetchNotification() {
    try {
      const resp = await http("notification/details");
      // console.log("resp", resp.data)
      setNotification(resp.data)
      setIsFetching(false);
    } catch (error) {
      console.log(error)
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <div className='sm:px-10 px-5 py-8 w-full'>
      <div className='mb-8'>
        <div className='text-xl font-bold mb-1'>Notification</div>
        <div className='text-xs text-neutral-500'>Send notifications to users</div>
      </div>

      <hr className='mb-10 border-slate-300 dark:border-neutral-700' />

      <SendNotificationModal>
        <div className='mb-10 flex gap-3 text-purple-500 items-center'>
          <BsSendCheck />
          <button>Send new notification</button>
        </div>
      </SendNotificationModal>

      <div className='mb-10 flex justify-center'>
        <div className='w-[300px] md:w-[500px] p-5 bg-slate-50 dark:bg-neutral-800 border-2 border-slate-200 dark:border-neutral-600 shadow-md rounded-lg'>
          {
            isFetching ?
              <>
                <Skeleton h={'25px'} mb={'16px'} />
                <Skeleton h={'200px'} mb={'10px'} />
              </> :
              !notification ?
                <>
                  <div className='text-sm font-bold mb-4'>No notification at the moment !</div>
                </> :
                <>
                  <div className='text-sm font-bold mb-4 underline underline-offset-2'>{notification.header}</div>
                  <div className='text-sm'>{notification.body}</div>
                </>
          }
        </div>
      </div>
    </div>
  )
}

export default NotificationClient