'use client'

import React, { useEffect } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import AppProvider from "@/contexts/appContext";
import { ThemeProvider } from '@/contexts/themeProvider';
import { MPWCheckoutProvider } from 'mpw-checkout-react';

function Providers({ children }: {
    children: React.ReactNode
}) {
    const [mounted, setMounted] = React.useState(false);
    useEffect(() => setMounted(true), []);
    return (
        <ThemeProvider>
            <ChakraProvider>
                <AppProvider>
                    <MPWCheckoutProvider>
                        {mounted && children}
                    </MPWCheckoutProvider>
                </AppProvider>
            </ChakraProvider>
        </ThemeProvider>
    )
}

export default Providers