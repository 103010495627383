"use client";

import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTheme } from "@/contexts/themeProvider";
import classNames from "classnames";
import { ImSpinner8 } from "react-icons/im";
import http from "@/lib/http";
import { handleResponseError } from "@/utils/errors";
import { IUpdateLoanRequest } from "@/types/global.types";

function UpdateLoanRequestModal({
  children,
  data,
}: {
  children: React.ReactNode;
  data: any;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { resolvedTheme } = useTheme();
  const toast = useToast();

  const [updateLoanRequestInput, setUpdateLoanRequestInput] =
    useState<IUpdateLoanRequest>({
      reference_code: data.reference_code,
      repayment_date: data.repayment_date,
      repayment_amount: data.repayment_amount,
      interest_amount: data.interest_amount,
      overdue_amount: data.overdue_amount,
      instalment_allowed: data.instalment_allowed,
      instalment_count: data.instalment_count,
      send_late_repayment_sms: data.send_late_repayment_sms,
      send_late_repayment_email: data.send_late_repayment_email,
      auto_debit_card_on_repayment_date: data.auto_debit_card_on_repayment_date,
      // auto_debit_bank_account_on_repayment_date: data.amount_plus,
      send_relation_overdue_repayment_sms:
        data.send_relation_overdue_repayment_sms,
      send_relation_overdue_repayment_email:
        data.send_relation_overdue_repayment_email,
      status: data.status,
      overdue_status: data.overdue_status,
      penalty_counter: data.penalty_counter,
      penalty: data.penalty,
      penalty_type: data.penalty_type,
      penalty_capped: data.penalty_capped,
      penalty_amount_plus: data.penalty_amount_plus,
      penalty_amount_plus_limit: data.penalty_amount_plus_limit,
    });
  const [isFetching, setIsFetching] = useState(false);

  async function serviceUpdate() {
    setIsFetching(true);
    try {
      const resp = await http("services/addon/loan/request/update", {
        ...updateLoanRequestInput,
      });
      // console.log("resp", resp.data)
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div onClick={onOpen} className="inline-block cursor-pointer">
        {children}
      </div>

      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        size={{ base: "sm", md: "lg" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            Update loan request
          </ModalHeader>
          <ModalCloseButton
            className={classNames("outline-none", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          />

          <ModalBody
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <div className="mb-3">
              <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold">
                {data.slug}
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  repayment_date
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.repayment_date}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      repayment_date: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  repayment_amount
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.repayment_amount}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      repayment_amount: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  interest_amount
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.interest_amount}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      interest_amount: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  overdue_amount
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.overdue_amount}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      overdue_amount: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  instalment_allowed
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.instalment_allowed}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      instalment_allowed: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  instalment_count
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.instalment_count}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      instalment_count: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  send_late_repayment_sms
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.send_late_repayment_sms}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      send_late_repayment_sms: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  send_late_repayment_email
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.send_late_repayment_email}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      send_late_repayment_email: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  auto_debit_card_on_repayment_date
                </div>
                <input
                  type="text"
                  value={
                    updateLoanRequestInput.auto_debit_card_on_repayment_date
                  }
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      auto_debit_card_on_repayment_date: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              {/* <div className='mb-5'>
                <div className='text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]'>auto_debit_bank_account_on_repayment_date</div>
                <input type='text' value={updateLoanRequestInput.auto_debit_bank_account_on_repayment_date} onChange={(e) => setUpdateLoanRequestInput({ ...updateLoanRequestInput, auto_debit_bank_account_on_repayment_date: e.target.value })} className='w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700' disabled={isFetching} />
              </div> */}

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  send_relation_overdue_repayment_sms
                </div>
                <input
                  type="text"
                  value={
                    updateLoanRequestInput.send_relation_overdue_repayment_sms
                  }
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      send_relation_overdue_repayment_sms: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  send_relation_overdue_repayment_email
                </div>
                <input
                  type="text"
                  value={
                    updateLoanRequestInput.send_relation_overdue_repayment_email
                  }
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      send_relation_overdue_repayment_email: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  Status
                </div>
                <select
                  value={updateLoanRequestInput.status}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      status: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                >
                  <option value="">Select an option</option>
                  <option value="Failed">Failed</option>
                  <option value="Processing">Processing</option>
                  <option value="Approved">Approved</option>
                  <option value="Overdue">Overdue</option>
                  <option value="Repaid">Repaid</option>
                </select>
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  overdue_status
                </div>
                <select
                  value={updateLoanRequestInput.overdue_status}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      overdue_status: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                >
                  <option value="">Select an option</option>
                  <option value="on">on</option>
                  <option value="off">off</option>
                </select>
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  penalty_counter
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.penalty_counter}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      penalty_counter: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  penalty
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.penalty}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      penalty: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  penalty_type
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.penalty_type}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      penalty_type: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  penalty_capped
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.penalty_capped}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      penalty_capped: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  penalty_amount_plus
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.penalty_amount_plus}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      penalty_amount_plus: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <div className="mb-5">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-500 mb-[2px]">
                  penalty_amount_plus_limit
                </div>
                <input
                  type="text"
                  value={updateLoanRequestInput.penalty_amount_plus_limit}
                  onChange={(e) =>
                    setUpdateLoanRequestInput({
                      ...updateLoanRequestInput,
                      penalty_amount_plus_limit: e.target.value,
                    })
                  }
                  className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                  disabled={isFetching}
                />
              </div>

              <button
                className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                onClick={() => serviceUpdate()}
                disabled={isFetching}
              >
                {isFetching ? (
                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </ModalBody>

          <ModalFooter
            className={classNames("", {
              "bg-neutral-800 text-neutral-100": resolvedTheme === "dark",
              "bg-slate-50 text-neutral-800": resolvedTheme === "light",
            })}
          >
            <button onClick={onClose}>Close</button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UpdateLoanRequestModal;
