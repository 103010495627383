'use client'

import React, { useEffect, useState } from 'react'
import ThemeButton from './ThemeButton';
import { Outlet, useNavigate } from 'react-router-dom';
import Loader from './Loader';

function AuthLayoutClient() {

    const navigate = useNavigate();
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const tk = localStorage.getItem('app-tkn');
        if (tk) {
            navigate('/d/overview', { replace: true });
        } else {
            setIsFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isFetching ? <Loader /> : <div className='w-full pt-3 bg-neutral-50 dark:bg-neutral-900 text-neutral-800 dark:text-neutral-100'>
                <div className='flex font-container'>
                    <div className='px-5 sm:py-2 py-2 sm:w-[300px] w-[200px] sm:min-h-[65px] flex justify-between items-center'>
                        <div className='cursor-pointer'>
                            <img src={'/logo.png'}
                                className='w-[100px]' alt='logo' />
                        </div>
                        <ThemeButton />
                    </div>
                </div>
                <div className='w-full h-screen bg-neutral-50 dark:bg-neutral-900 font-container'>
                    <Outlet />
                </div>
            </div>}
        </>
    )
}

export default AuthLayoutClient