import React, { useState, useRef } from 'react';

interface CustomSelectProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  options: any[];
  optionsKey: {
    value: string,
    data: string[]
  }
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  setValue,
  optionsKey
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLowerCase();
    const newFilteredOptions = options.filter((option) =>
      option[optionsKey.data[0]].toLowerCase().includes(searchTerm)
    );
    setFilteredOptions(newFilteredOptions);
  };

  const handleOpen = () => setIsOpen(!isOpen);
  const handleOptionChange = (selectedValue: any) => {
    searchInputRef.current!.value = `${selectedValue}`;
    setValue(selectedValue);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      {/* <label htmlFor="select" className="block text-sm font-medium text-gray-700">
        {label}
      </label> */}
      <div
        className="relative w-full cursor-pointer"
        >
        <input
          ref={searchInputRef}
          type="search"
          className="hidden"
          placeholder="Recipient"
          value={options.filter(o => o[optionsKey.value] === value).length > 0 ?
            `${options.filter(o => o[optionsKey.value] === value)[0][optionsKey.value]}`
            : ''}
          readOnly
        />
        <div onClick={handleOpen} className='flex justify-between px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'>
          <div>
            {options.filter(o => o[optionsKey.value] === value).length > 0 ?
              `${options.filter(o => o[optionsKey.value] === value)[0][optionsKey.data[0]]}`
              : ''}
          </div>
        </div>

        {isOpen && <div className='absolute py-3 bg-neutral-50 border-2 border-slate-600 dark:border-neutral-500 dark:bg-neutral-600 w-full shadow-2xl'>
          <div className='w-full mb-3 px-3'>
            <input type="text" className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' placeholder='Enter search keyword' onChange={handleSearch} />
          </div>

          <div className='w-full max-h-[200px] overflow-y-auto'>
            {filteredOptions.length > 0 && filteredOptions.map((o, i) => (
              <div key={i} onClick={() => handleOptionChange(o[optionsKey.value])} className='w-full text-xs px-3 py-2 border-t-2 border-t-slate-600 dark:border-t-neutral-500 hover:bg-slate-100 hover:dark:bg-neutral-700 cursor-pointer'>
                <div>{optionsKey.data.length > 0 ? o[optionsKey.data[0]].toUpperCase() : ""}</div>
                {/* <div>{o.number} - {o.bank}</div> */}
              </div>
            ))}
          </div>
        </div>}

      </div>
    </div>
  );
};

export default CustomSelect;
