import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoReturnUpBackSharp } from "react-icons/io5";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { FaCreditCard, FaRegAddressCard } from "react-icons/fa6";
import { GrOverview } from "react-icons/gr";
import { CgToolbox } from "react-icons/cg";
import { GrUserSettings } from "react-icons/gr";
import { PiUsersThreeBold } from "react-icons/pi";
// import { GiPayMoney } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
// import BusinessMobileDrawer from './drawers/BusinessMobileDrawer';
import { RiMailSendLine } from "react-icons/ri";
import { useApp } from "@/contexts/appContext";
import { MdExitToApp } from "react-icons/md";

export function BusinessMenu({
  isMobile,
  name,
}: {
  isMobile: boolean;
  name: string | null;
}) {
  const { logout } = useApp();
  const { pathname } = useLocation();

  console.log(pathname);
  // const navigate = useNavigate();
  // const [isCommissionTabOpened, setIsCommissionTabOpened] = useState(false);
  const [isAccountTabOpened, setIsAccountTabOpened] = useState(false);
  const [isServicesTabOpened, setIsServicesTabOpened] = useState(false);
  const [isWalletTabOpened, setIsWalletTabOpened] = useState(false);
  const [isSubscriptonTabOpened, setIsSubscriptonTabOpened] = useState(false);
  const [isManagedBusinessTabOpened, setIsManagedBusinessTabOpened] =
    useState(false);

  function getPath() {
    const split_path = pathname.split("/");
    return split_path[2];
  }

  function getSubPath(path = 3) {
    const split_path = pathname.split("/");
    return split_path[path];
  }

  async function logoutFunc() {
    try {
      await logout();
      // navigate('/auth/login', { replace: true });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        className={classNames("min-h-lvh dark:bg-neutral-800 ", {
          "hidden md:block w-[250px] bg-slate-50 pt-10": !isMobile,
          "block w-full": isMobile,
        })}
      >
        <div
          className={classNames("p-8", {
            "py-8": !isMobile,
            "py-0": isMobile,
          })}
        >
          <div className="flex items-center justify-between text-xs text-slate-500 dark:text-neutral-400">
            <button
              onClick={() => logoutFunc()}
              className="flex items-center gap-2"
            >
              <IoReturnUpBackSharp />
              <div>Exit</div>
            </button>
            <div>Business</div>
          </div>
          <div className="flex flex-col items-end">
            <div className="mb-10 text-sm font-bold">{name && name}</div>
          </div>
        </div>

        <div className="flex flex-col items-end">
          <Link
            to={`/d/overview`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "overview",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "overview" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Overview</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "overview",
                    "bg-neutral-300": getPath() !== "overview",
                  }
                )}
              >
                <GrOverview
                  className={classNames({
                    "text-neutral-100": getPath() === "overview",
                    "text-neutral-900": getPath() !== "overview",
                  })}
                />
              </div>
            </div>
          </Link>
          {/* to={`/d/wallet`} */}
          <button
            onClick={() =>
              isWalletTabOpened
                ? setIsWalletTabOpened(false)
                : setIsWalletTabOpened(true)
            }
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "wallet",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "wallet" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Wallet</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "wallet",
                    "bg-neutral-300": getPath() !== "wallet",
                  }
                )}
              >
                <FaCreditCard
                  className={classNames({
                    "text-neutral-100": getPath() === "wallet",
                    "text-neutral-900": getPath() !== "wallet",
                  })}
                />
              </div>
            </div>
          </button>

          {isWalletTabOpened && (
            <div className="flex flex-col items-end w-full px-8 bg-slate-200 dark:bg-neutral-700">
              <Link
                to={`/d/wallet/details`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "wallet" && getSubPath() === "details",
                  })}
                >
                  Details
                </div>
              </Link>
              {/* <Link to={`/d/wallet/transactions`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "wallet" && getSubPath() === "transactions",
                            })}>Transactions</div>
                        </Link> */}
              <Link
                to={`/d/wallet/transfer_recipients`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "wallet" &&
                      getSubPath() === "transfer_recipients",
                  })}
                >
                  Transfer Recipient
                </div>
              </Link>
            </div>
          )}

          <button
            onClick={() =>
              isServicesTabOpened
                ? setIsServicesTabOpened(false)
                : setIsServicesTabOpened(true)
            }
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "services",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "services" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Services</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900":
                      getPath() === "services" || isServicesTabOpened,
                    "bg-neutral-300":
                      getPath() !== "services" && !isServicesTabOpened,
                  }
                )}
              >
                <CgToolbox
                  className={classNames({
                    "text-neutral-100":
                      getPath() === "services" || isServicesTabOpened,
                    "text-neutral-900":
                      getPath() !== "services" && !isServicesTabOpened,
                  })}
                />
              </div>
            </div>
          </button>

          {isServicesTabOpened && (
            <div className="flex flex-col items-end w-full px-8 bg-slate-200 dark:bg-neutral-700">
              <Link
                to={`/d/services/details`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" && getSubPath() === "details",
                  })}
                >
                  Details
                </div>
              </Link>
              {/* <Link
                to={`/d/services/transactions`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" &&
                      getSubPath() === "transactions",
                  })}
                >
                  Transactions
                </div>
              </Link> */}
              <Link
                to={`/d/services/loan`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" &&
                      getSubPath() === "loan" &&
                      !getSubPath(4),
                  })}
                >
                  Loan
                </div>
              </Link>
              <Link
                to={`/d/services/investment`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" &&
                      getSubPath() === "investment" &&
                      !getSubPath(4),
                  })}
                >
                  Investment
                </div>
              </Link>
              <Link
                to={`/d/services/savings`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" &&
                      getSubPath() === "savings" &&
                      !getSubPath(4),
                  })}
                >
                  Savings
                </div>
              </Link>
              <Link
                to={`/d/services/payments`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" &&
                      getSubPath() === "payments" &&
                      !getSubPath(4),
                  })}
                >
                  Payments
                </div>
              </Link>
              <Link
                to={`/d/services/bankaccount`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" &&
                      getSubPath() === "bankaccount" &&
                      !getSubPath(4),
                  })}
                >
                  Bank account
                </div>
              </Link>
              <Link
                to={`/d/services/cards`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "services" &&
                      getSubPath() === "cards" &&
                      !getSubPath(4),
                  })}
                >
                  Cards
                </div>
              </Link>
              {/* <Link to={`/d/services/loan/requests`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "services" && getSubPath() === "loan" && getSubPath(4) === "requests",
                            })}>Loan requests</div>
                        </Link> */}
            </div>
          )}

          <Link
            to={`/d/transactions`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700":
                  pathname === "/d/account/details",
                "bg-neutral-50 dark:bg-neutral-800":
                  pathname !== "/d/account/details" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Transactions</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": pathname === "/d/transactions",
                    "bg-neutral-300": pathname !== "/d/transactions",
                  }
                )}
              >
                <FaRegAddressCard
                  className={classNames({
                    "text-neutral-100": pathname === "/d/transactions",
                    "text-neutral-900": pathname !== "/d/transactions",
                  })}
                />
              </div>
            </div>
          </Link>

          <Link
            to={`/d/team`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "team",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "team" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Team</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "team",
                    "bg-neutral-300": getPath() !== "team",
                  }
                )}
              >
                <PiUsersThreeBold
                  className={classNames({
                    "text-neutral-100": getPath() === "team",
                    "text-neutral-900": getPath() !== "team",
                  })}
                />
              </div>
            </div>
          </Link>

          <Link
            to={`/d/users`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "users",
                "bg-neutral-50 dark:bg-neutral-800": getPath() !== "users",
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Users</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "users",
                    "bg-neutral-300": getPath() !== "users",
                  }
                )}
              >
                <PiUsersThreeBold
                  className={classNames({
                    "text-neutral-100": getPath() === "users",
                    "text-neutral-900": getPath() !== "users",
                  })}
                />
              </div>
            </div>
          </Link>

          {/* <button onClick={() => isCommissionTabOpened ? setIsCommissionTabOpened(false) : setIsCommissionTabOpened(true)} className={classNames(' px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end', {
                        'bg-slate-200 dark:bg-neutral-700': getPath() === "commission",
                        'bg-neutral-50 dark:bg-neutral-800': getPath() !== "commission" && !isMobile,
                    })}>
                        <div className='flex items-center gap-2'>
                            <div className="text-xs">Commission</div>
                            <div className={classNames('w-7 h-7 p-2 rounded-full flex justify-center items-center', {
                                'bg-purple-900': getPath() === "commission" || isCommissionTabOpened,
                                'bg-neutral-300': getPath() !== "commission" || !isCommissionTabOpened,
                            })}>
                                <GiPayMoney className={classNames({
                                    'text-neutral-100': getPath() === "commission" || isCommissionTabOpened,
                                    'text-neutral-900': getPath() !== "commission" && !isCommissionTabOpened,
                                })} />
                            </div>
                        </div>
                    </button>

                    {isCommissionTabOpened && <div className='flex flex-col items-end w-full px-8 bg-slate-200 dark:bg-neutral-700'>
                        <Link to={`/d/commission/details`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "commission" && getSubPath() === "details",
                            })}>Details</div>
                        </Link>
                        <Link to={`/d/commission/payments`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "commission" && getSubPath() === "payments",
                            })}>Payments</div>
                        </Link>
                    </div>} */}

          <button
            onClick={() =>
              isSubscriptonTabOpened
                ? setIsSubscriptonTabOpened(false)
                : setIsSubscriptonTabOpened(true)
            }
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700":
                  getPath() === "subscription",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "subscription" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Subscription</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900":
                      getPath() === "subscription" || isSubscriptonTabOpened,
                    "bg-neutral-300":
                      getPath() !== "subscription" && !isSubscriptonTabOpened,
                  }
                )}
              >
                <TbMoneybag
                  className={classNames({
                    "text-neutral-100":
                      getPath() === "subscription" || isSubscriptonTabOpened,
                    "text-neutral-900":
                      getPath() !== "subscription" && !isSubscriptonTabOpened,
                  })}
                />
              </div>
            </div>
          </button>

          {isSubscriptonTabOpened && (
            <div className="flex flex-col items-end w-full px-8 bg-slate-200 dark:bg-neutral-700">
              {/* <Link to={`/d/subscription/options`} className='flex items-center gap-2 py-2 pr-9'>
                            <div className={classNames("text-sm", {
                                "text-purple-500 font-bold": getPath() === "subscription" && getSubPath() === "options",
                            })}>Options</div>
                        </Link> */}
              <Link
                to={`/d/subscription/payments`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "subscription" &&
                      getSubPath() === "payments",
                  })}
                >
                  Payments
                </div>
              </Link>
            </div>
          )}

          <Link
            to={`/d/kyc`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "kyc",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "kyc" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">KYC</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "kyc",
                    "bg-neutral-300": getPath() !== "kyc",
                  }
                )}
              >
                <FaRegAddressCard
                  className={classNames({
                    "text-neutral-100": getPath() === "kyc",
                    "text-neutral-900": getPath() !== "kyc",
                  })}
                />
              </div>
            </div>
          </Link>

          <Link
            to={`/d/notification`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700":
                  getPath() === "notification",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "notification" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Notification</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "notification",
                    "bg-neutral-300": getPath() !== "notification",
                  }
                )}
              >
                <RiMailSendLine
                  className={classNames({
                    "text-neutral-100": getPath() === "notification",
                    "text-neutral-900": getPath() !== "notification",
                  })}
                />
              </div>
            </div>
          </Link>

          <Link
            to={`/d/account/details`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700":
                  pathname === "/d/account/details",
                "bg-neutral-50 dark:bg-neutral-800":
                  pathname !== "/d/account/details" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Manage Account</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": pathname === "/d/account/details",
                    "bg-neutral-300": pathname !== "/d/account/details",
                  }
                )}
              >
                <FaRegAddressCard
                  className={classNames({
                    "text-neutral-100": pathname === "/d/account/details",
                    "text-neutral-900": pathname !== "/d/account/details",
                  })}
                />
              </div>
            </div>
          </Link>

          {/* <button
            onClick={() => {
              isAccountTabOpened
                ? setIsAccountTabOpened(false)
                : setIsAccountTabOpened(true);
								
            }}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "account",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "account" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Manage Account</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900":
                      getPath() === "account" || isAccountTabOpened,
                    "bg-neutral-300":
                      getPath() !== "account" && !isAccountTabOpened,
                  }
                )}
              >
                <GrUserSettings
                  className={classNames({
                    "text-neutral-100":
                      getPath() === "account" || isAccountTabOpened,
                    "text-neutral-900":
                      getPath() !== "account" && !isAccountTabOpened,
                  })}
                />
              </div>
            </div>
          </button> */}

          {/* {isAccountTabOpened && (
            <div className="flex flex-col items-end w-full px-8 bg-slate-200 dark:bg-neutral-700">
              <Link
                to={`/d/business/documents`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "account" && getSubPath() === "documents",
                  })}
                >
                  Documents
                </div>
              </Link>
              <Link
                to={`/d/account/details`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "account" && getSubPath() === "details",
                  })}
                >
                  Details
                </div>
              </Link>
            </div>
          )} */}

          <button
            onClick={() =>
              setIsManagedBusinessTabOpened(!isManagedBusinessTabOpened)
            }
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "details",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "details" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Manage Business</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900":
                      getPath() === "account" || isManagedBusinessTabOpened,
                    "bg-neutral-300":
                      getPath() !== "account" && !isManagedBusinessTabOpened,
                  }
                )}
              >
                <GrUserSettings
                  className={classNames({
                    "text-neutral-100":
                      getPath() === "account" || isManagedBusinessTabOpened,
                    "text-neutral-900":
                      getPath() !== "account" && !isManagedBusinessTabOpened,
                  })}
                />
              </div>
            </div>
          </button>

          {/* <Link
            to={`/d/business/details`}
            className={classNames(
              " px-8 py-3 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "business",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "business" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Manage business</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "business",
                    "bg-neutral-300": getPath() !== "business",
                  }
                )}
              >
                <RiMailSendLine
                  className={classNames({
                    "text-neutral-100": getPath() === "business",
                    "text-neutral-900": getPath() !== "business",
                  })}
                />
              </div>
            </div>
          </Link> */}

          {isManagedBusinessTabOpened && (
            <div className="flex flex-col items-end w-full px-8 bg-slate-200 dark:bg-neutral-700">
              <Link
                to={`/d/business/documents`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "business" && getSubPath() === "documents",
                  })}
                >
                  Documents
                </div>
              </Link>
              <Link
                to={`/d/business/details`}
                className="flex items-center gap-2 py-2 pr-9"
              >
                <div
                  className={classNames("text-sm", {
                    "text-purple-500 font-bold":
                      getPath() === "business" && getSubPath() === "details",
                  })}
                >
                  Details
                </div>
              </Link>
            </div>
          )}

          <button
            onClick={() => logoutFunc()}
            className={classNames(
              " px-8 py-3 mt-10 text-neutral-700 dark:text-neutral-50 text-[14px] font-bold opacity-100 w-full flex flex-col items-end",
              {
                "bg-slate-200 dark:bg-neutral-700": getPath() === "logout",
                "bg-neutral-50 dark:bg-neutral-800":
                  getPath() !== "logout" && !isMobile,
              }
            )}
          >
            <div className="flex items-center gap-2">
              <div className="text-xs">Logout</div>
              <div
                className={classNames(
                  "w-7 h-7 p-2 rounded-full flex justify-center items-center",
                  {
                    "bg-purple-900": getPath() === "logout",
                    "bg-neutral-300": getPath() !== "logout",
                  }
                )}
              >
                <MdExitToApp
                  className={classNames({
                    "text-neutral-100": getPath() === "logout",
                    "text-neutral-900": getPath() !== "logout",
                  })}
                />
              </div>
            </div>
          </button>

          <div
            className={classNames({
              "mt-[100px]": !isMobile,
              "mt-[50px]": isMobile,
            })}
          ></div>
          {/* <Link to='/d/businesses' className='flex items-center gap-2 px-8 text-xs underline text-slate-500 dark:text-neutral-400'>
                        <IoReturnUpBackSharp />
                        <div>Select another business</div>
                    </Link> */}
        </div>
      </div>
    </>
  );
}

function BusinessesLayout({ name }: { name: string | null }) {
  return (
    <>
      <BusinessMenu isMobile={false} name={name} />
    </>
  );
}

export default BusinessesLayout;
