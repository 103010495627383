"use client";

import http from "@/lib/http";
import { BusinessMetric } from "@/types/global.types";
import { Skeleton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CgToolbox } from "react-icons/cg";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import {
  FcCancel,
  FcCheckmark,
  FcSynchronize,
  FcViewDetails,
} from "react-icons/fc";
import { IoLogoUsd } from "react-icons/io5";
import { PiCurrencyNgnDuotone, PiDotsThreeOutlineBold, PiUsersThreeBold } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";

function BusinessOverviewClient() {
  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(true);
  const [metric, setMetric] = useState<BusinessMetric | null>(null);

  async function getBusinessMetric() {
    try {
      const resp = await http<BusinessMetric>("metrics/details");
      // console.log("metric", resp.data);
      setMetric(resp.data);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBusinessMetric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="max-h-screen min-h-screen overflow-y-scroll">
      <div className="flex items-center gap-3 mb-10 text-xs text-slate-500">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/d/overview");
          }}
        >
          Business
        </div>
        <div>
          <FaAngleRight />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/d/overview");
          }}
        >
          overview
        </div>
      </div>

      <div className="mb-10 text-xl">Business Overview</div>

      <div className="mb-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
            <PiUsersThreeBold className="w-6 h-6" />
            <div>Users</div>
          </div>
          {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
        </div>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              Total
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">
                  Total users registered or created
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.users.total}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/users/list"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              Pending
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <FcSynchronize className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Users awaiting approval</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.users.pending}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/users/list"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              Approved
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-green-100 sm:p-3">
                <FcCheckmark className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Approved users</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.users.approved}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/users/list"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              Banned
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-red-100 sm:p-3">
                <FcCancel className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">
                  Users banned from services
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.users.banned}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/users/list"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
            <CgToolbox className="w-6 h-6" />
            <div>Services</div>
          </div>
          {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
        </div>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Loan Service</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.loan}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Savings Service</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.saving}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Approved */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-green-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Investment Service</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.investment}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/investment"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Banned */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-red-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Payment Service</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.payment}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/payments"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Bank Accounts</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.bank_account}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/bankaccount"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Linked Cards</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.linked_card}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Approved */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-green-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">
                  Total Linked Bank Account
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.linked_bank_account}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/investment"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Banned */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-red-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Transfer Recipient</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.transfer_recipient}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/payments"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Physical Cards</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.physical_card}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <FcViewDetails className="w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Virtual Cards</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric.service.virtual_card}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
            <CgToolbox className="w-6 h-6" />
            <div>Credit</div>
          </div>
          {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
        </div>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Count</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric?.credit?.ngn?.counts}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

					<div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Value</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric?.credit?.ngn?.value}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

					<div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <IoLogoUsd className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Count</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                     <span>{metric?.credit?.usd?.value}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

					<div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <IoLogoUsd className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Value</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                     <span>{metric?.credit?.usd?.value}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

			<div className="mb-10">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3 px-1 mb-4 text-sm font-bold">
            <CgToolbox className="w-6 h-6" />
            <div>Debit</div>
          </div>
          {/* <Link to={`/d/users/list`} className="flex items-center gap-2">
            <div className="text-xs font-bold underline">View</div>
            <div className="p-1 rounded-full bg-neutral-200 dark:bg-neutral-800">
              <PiDotsThreeOutlineBold className="w-4 h-4" />
            </div>
          </Link> */}
        </div>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 sm:gap-6">
          <div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Count</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric?.debits?.ngn?.counts}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

					<div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Pending */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-orange-100 sm:p-3">
                <PiCurrencyNgnDuotone className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Value</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                      <span>{metric?.debits?.ngn?.value}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/savings"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

					<div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <IoLogoUsd className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Count</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                     <span>{metric?.debits?.usd?.value}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

					<div className="px-3 py-2 rounded-lg shadow-md sm:px-5 sm:py-5 bg-slate-50 dark:bg-neutral-800">
            <div className="text-[12px] mb-4 font-bold inline-block px-0">
              {/* Total */}
            </div>
            <div className="flex items-center gap-3">
              <div className="p-1 bg-sky-100 sm:p-3">
                <IoLogoUsd className="text-black w-7 h-7" />
              </div>
              <div className="w-full">
                <div className="mb-3 text-[10px]">Total Value</div>
                <div className="flex items-center justify-between">
                  <div className="text-xl text-opacity-80 ">
                    {!isFetching && metric ? (
                     <span>{metric?.debits?.usd?.value}</span>
                    ) : (
                      <Skeleton h={"20px"} w={"50px"} />
                    )}
                  </div>
                  {/* <div className="flex items-center gap-1 text-[10px]">
                    <Link to={"/d/services/loan"}>view</Link>
                    <FaLongArrowAltRight className="w-2 h-2" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessOverviewClient;
