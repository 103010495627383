import React, { createContext, useState, useEffect, useContext } from 'react';
import { ITheme, IThemeContext } from '@/types/global.types';

const ThemeContext = createContext<IThemeContext | undefined>(undefined);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
    const [resolvedTheme, setResolvedTheme] = useState<ITheme>('light');
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        const storedTheme = localStorage.getItem('theme') as ITheme | null;
        if (storedTheme) {
            setResolvedTheme(storedTheme);
        } else {
            const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            setResolvedTheme(prefersDark ? 'dark' : 'light');
        }
        setMounted(true);
    }, []);

    useEffect(() => {
        if (mounted) {
            localStorage.setItem('theme', resolvedTheme);
            document.documentElement.setAttribute('data-theme', resolvedTheme);
            document.documentElement.classList.toggle('dark', resolvedTheme === 'dark');
        }
    }, [resolvedTheme, mounted]);

    const setTheme = (theme: ITheme) => {
        setResolvedTheme(theme);
    };

    return (
        <ThemeContext.Provider value={{ resolvedTheme, setTheme }}>
            {mounted && children}
        </ThemeContext.Provider>
    );
}

export function useTheme() {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}