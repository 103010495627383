'use client'

import React, { useEffect } from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
} from '@chakra-ui/react'
// import { FaAngleDown } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@/contexts/themeProvider';
import { LuMenuSquare } from "react-icons/lu";

function BusinessMobileDrawer({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const {pathname} = useLocation();
    const {resolvedTheme} = useTheme();

    useEffect(() => {
        onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <>
            <div className='flex gap-2 px-5 items-center cursor-pointer' onClick={() => { isOpen ? onClose() : onOpen() }}>
                <div className='flex items-center'>
                    {/* <div className='flex items-center gap-2 pl-4 pr-10 py-1 text-neutral-800 dark:text-neutral-100 rounded-l-2xl text-[11px] -mr-7 relative font-extrabold'>
                                <FaAngleLeft className='w-4 h-4' />
                                <div>{getPath().length > 0 && upperCaseFirst(getPath())}</div>
                            </div> */}
                    <div className='rounded-full md:p-2 p-1 dark:bg-slate-900 bg-slate-200 relative z-10 border-[3px] dark:border-neutral-800 border-neutral-200'>
                        <LuMenuSquare className='dark:text-neutral-100 text-neutral-700 md:w-5 w-5 md:h-5 h-5' />
                    </div>
                </div>
            </div>

            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent bg={resolvedTheme === "dark" ? '#262626' : '#fff'} color={resolvedTheme === "dark" ? '#fff' : '#000'}>
                    <DrawerCloseButton />
                    <DrawerHeader><div className='text-[14px]'>Menu</div></DrawerHeader>

                    <DrawerBody>
                        {children}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default BusinessMobileDrawer