"use client";

import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import http from "@/lib/http";
import { useToast } from "@chakra-ui/react";
// import classNames from 'classnames';
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";
import { ICreateLoanInput } from "@/types/global.types";
import { removeUnderscore, upperCaseFirst } from "@/utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Tooltip } from "antd";
import { HiInformationCircle } from "react-icons/hi";

function BusinessServicesLoanUpdateClient() {
  // const { user } = useApp();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();
  const [form] = Form.useForm();

  const [isSending, setIsSending] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const [upadeLoanInput, setUpdateLoanInput] = useState<ICreateLoanInput>({
    // id: "",
    currency: "",
    // type: "",
    quantity: "",
    name: "",
    collection: "",
    rate: "",
    rate_type: "",
    rate_capped: "",
    rate_amount_plus: "",
    rate_amount_plus_limit: "",
    // penalty: "",
    // penalty_type: "",
    // penalty_capped: "",
    // penalty_amount_plus: "",
    // penalty_amount_plus_limit: "",
    min_amount: "",
    max_amount: "",
    min_duration: "",
    max_duration: "",
    active: "",
    // rate_counter: "",
    // penalty_counter: "",
    //@ts-ignore
    instalment: {
      allowed: "",
      min_count: "",
      max_count: "",
      min_duration: "",
      max_duration: "",
      min_amount: "",
      max_amount: "",
      // penalty: "",
    },
    auto_approve: "",
    auto_decline: "",
    send_late_repayment_sms: "",
    send_late_repayment_email: "",
    auto_debit_card_on_repayment_date: "",
    auto_debit_bank_account_on_repayment_date: "",
    card_meter: "",
    bank_meter: "",
    allow_new_request_on_current_loan: "",
    send_request_status_sms: "",
    send_request_status_email: "",
    send_relation_overdue_repayment_sms: "",
    send_relation_overdue_repayment_email: "",
    // repayment_extension_allowed: "",
    penalty: "",
    penalty_type: "",
    penalty_capped: "",
    penalty_amount_plus: "",
    penalty_amount_plus_limit: "",
    description: "",
    request_info: [],
  });

  const infos = [
    {
      key: "currency",
      value:
        "The type of currency in which the loan amount is denominated (e.g., USD, EUR, etc.)",
    },
    {
      key: "groupCategory",
      value:
        "The group or category to which this loan/product belongs (e.g., 'Student Loan')",
    },
    {
      key: "quantity",
      value: "The total number of times this item is available for users",
    },
    {
      key: "name",
      value:
        "The title or name of the loan or financial product (e.g., 'Instant Loan')",
    },
    {
      key: "description",
      value: "A detailed explanation or overview of the loan/product",
    },
    {
      key: "rate",
      value: "The fee or interest rate applied to the loan/product",
    },
    {
      key: "rate_type",
      value:
        "Defines if the rate is a flat amount or a percentage of the principal",
    },
    {
      key: "rate_capped",
      value: "The maximum limit for the rate (if applicable)",
    },
    {
      key: "rate_amount_plus",
      value: "Additional charges added to the loan amount",
    },
    {
      key: "rate_amount_plus_limit",
      value:
        "The maximum value or limit for the additional amount added on top",
    },
    {
      key: "penalty",
      value: "The fee charged in case of delayed or missed repayment",
    },
    {
      key: "penalty_type",
      value: "Specifies if the penalty is a flat fee or percentage",
    },
    {
      key: "penalty_capped",
      value: "The maximum cap for the penalty fee (if applicable)",
    },
    {
      key: "penalty_amount_plus",
      value: "Additional penalty amount beyond the regular penalty",
    },
    {
      key: "penalty_amount_plus_limit",
      value: "Maximum limit for the additional penalty amount",
    },
    {
      key: "min_amount",
      value: "The minimum loan amount that can be requested",
    },
    {
      key: "max_amount",
      value: "The maximum loan amount that can be requested",
    },
    {
      key: "min_duration",
      value: "The shortest period a loan can be taken for",
    },
    {
      key: "max_duration",
      value: "The longest period a loan can be taken for",
    },
    {
      key: "active",
      value:
        "Defines if the loan/product is currently live and available (Yes/No)",
    },
    {
      key: "rate_counter",
      value: "Tracks the number of times the rate is applied",
    },
    {
      key: "penalty_counter",
      value: "Tracks the number of times the penalty is applied",
    },
    {
      key: "penalty_on",
      value:
        "Specifies the conditions under which the penalty is applied (e.g., missed payments)",
    },
    {
      key: "allowed",
      value:
        "Indicates whether installments are allowed for repayment (Yes/No)",
    },
    {
      key: "min_count",
      value: "The minimum number of installments allowed for repayment",
    },
    {
      key: "max_count",
      value: "The maximum number of installments allowed for repayment",
    },
    {
      key: "min_duration",
      value: "The shortest duration allowed for installment repayments",
    },
    {
      key: "max_duration",
      value: "The longest duration allowed for installment repayments",
    },
    {
      key: "min_amount",
      value: "The minimum amount allowed for an installment payment",
    },
    {
      key: "max_amount",
      value: "The maximum amount allowed for an installment payment",
    },
    {
      key: "penalty",
      value: "Penalty charged if an installment payment is late",
    },
    {
      key: "auto_approve",
      value:
        "Whether the loan/product is automatically approved upon application (Yes/No)",
    },
    {
      key: "auto_decline",
      value:
        "Whether the loan/product is automatically declined upon application (Yes/No)",
    },
    {
      key: "send_late_repayment_sms",
      value:
        "Indicates whether to send an SMS notification for late repayment (Yes/No)",
    },
    {
      key: "send_late_repayment_email",
      value:
        "Indicates whether to send an email notification for late repayment (Yes/No)",
    },
    {
      key: "auto_debit_card_on_repayment_date",
      value:
        "Defines whether the user's card will be automatically debited on the repayment date",
    },
    {
      key: "auto_debit_bank_account_on_repayment_date",
      value:
        "Defines whether the user's bank account will be automatically debited on the repayment date",
    },
    {
      key: "card_meter",
      value:
        "The number of cards a user must link before they are eligible for this loan/product",
    },
    {
      key: "bank_meter",
      value:
        "The number of bank accounts a user must link before they are eligible",
    },
    {
      key: "allow_new_request_on_current_loan",
      value:
        "Whether the user is allowed to request a new loan if they have an outstanding one",
    },
    {
      key: "send_request_status_sms",
      value:
        "Whether to send an SMS notification for status updates on loan requests (approved or declined)",
    },
    {
      key: "send_request_status_email",
      value:
        "Whether to send an email notification for status updates on loan requests (approved or declined)",
    },
    {
      key: "send_relation_overdue_repayment_sms",
      value:
        "Whether to send an SMS notification to relations when the loan is overdue for repayment",
    },
    {
      key: "send_relation_overdue_repayment_email",
      value:
        "Whether to send an email notification to relations when the loan is overdue for repayment",
    },
    {
      key: "repayment_extension_allowed",
      value:
        "Indicates whether an extension on the repayment date is permitted (Yes/No)",
    },
  ];

  const checkForInfo = (key: string) => {
    let checking = infos.filter((item) => item.key === key);
    if (checking.length > 0) {
      return (
        <div>
          <Tooltip title={checking[0].value}>
            <HiInformationCircle className="w-5 h-5 pl-1 !text-yellow-500 cursor-pointer" />
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  const isSelectTag = {
    currency: ["NGN", "USD"],
    // "type": ["DIRECT", "ALTERNATIVE"],
    active: ["Yes", "No"],
    rate_type: ["flat", "percentage"],
    penalty_type: ["flat", "percentage"],
    // "rate_counter": ["Day", "Week", "Month", "Year"],
    penalty_counter: ["Day", "Week", "Month", "Year"],
    // penalty: ["Yes", "No"],
    auto_approve: ["Yes", "No"],
    auto_decline: ["Yes", "No"],
    send_late_repayment_sms: ["Yes", "No"],
    send_late_repayment_email: ["Yes", "No"],
    auto_debit_card_on_repayment_date: ["Yes", "No"],
    auto_debit_bank_account_on_repayment_date: ["Yes", "No"],
    allow_new_request_on_current_loan: ["Yes", "No"],
    send_request_status_sms: ["Yes", "No"],
    send_request_status_email: ["Yes", "No"],
    send_relation_overdue_repayment_sms: ["Yes", "No"],
    send_relation_overdue_repayment_email: ["Yes", "No"],
    // "repayment_extension_allowed": ["Yes", "No"],
    allowed: ["Yes", "No"],
    required: ["Yes", "No"],
  };

  const isSelectTagRequireInfo = {
    required: ["Yes", "No"],
    type: [
      "text",
      "alphanumeric",
      "number",
      "video",
      "image",
      "audio",
      "select",
    ],
  };

  async function getLoan() {
    // console.log(upadeLoanInput)
    try {
      const resp = await http("services/addon/loan/list", { id: params.id });
      console.log("list resp", resp.data);
      if (!resp.data || resp.data.length === 0) {
        navigate("/d/services/loan");
      } else {
        //@ts-ignore
        setUpdateLoanInput({
          // id: params.id,
          currency: resp.data[0]?.currency,
          quantity: resp.data[0]?.quantity,
          name: resp.data[0]?.name,
          collection: resp.data[0]?.collection,
          rate: resp.data[0]?.rate,
          rate_type: resp.data[0]?.rate_type,
          rate_capped: resp.data[0]?.rate_capped,
          rate_amount_plus: resp.data[0]?.rate_amount_plus,
          rate_amount_plus_limit: resp.data[0]?.rate_amount_plus_limit,
          min_amount: resp.data[0]?.min_amount,
          max_amount: resp.data[0]?.max_amount,
          min_duration: resp.data[0]?.min_duration,
          max_duration: resp.data[0]?.max_duration,
          active: resp.data[0]?.active,

          //@ts-ignore
          instalment: {
            allowed: resp.data[0]?.instalment?.allowed,
            min_count: resp.data[0]?.instalment?.min_count,
            max_count: resp.data[0]?.instalment?.max_count,
            min_duration: resp.data[0]?.instalment?.min_duration,
            max_duration: resp.data[0]?.instalment?.max_duration,
            min_amount: resp.data[0]?.instalment?.min_amount,
            max_amount: resp.data[0]?.instalment?.max_amount,
            // penalty: resp.data[0]?.instalment?.penalty,
          },

          auto_approve: resp.data[0]?.auto_approve,
          auto_decline: resp.data[0]?.auto_decline,
          send_late_repayment_sms: resp.data[0]?.send_late_repayment_sms,
          send_late_repayment_email: resp.data[0]?.send_late_repayment_email,
          auto_debit_card_on_repayment_date:
            resp.data[0]?.auto_debit_card_on_repayment_date,
          auto_debit_bank_account_on_repayment_date:
            resp.data[0]?.auto_debit_bank_account_on_repayment_date,
          card_meter: resp.data[0]?.card_meter,
          bank_meter: resp.data[0]?.bank_meter,
          allow_new_request_on_current_loan:
            resp.data[0]?.allow_new_request_on_current_loan,
          send_request_status_sms: resp.data[0]?.send_request_status_sms,
          send_request_status_email: resp.data[0]?.send_request_status_email,
          send_relation_overdue_repayment_sms:
            resp.data[0]?.send_relation_overdue_repayment_sms,
          penalty: resp.data[0]?.penalty,
          penalty_type: resp.data[0]?.penalty_type,
          penalty_capped: resp.data[0]?.penalty_capped,
          penalty_amount_plus: resp.data[0]?.penalty_amount_plus,
          penalty_amount_plus_limit: resp.data[0]?.penalty_amount_plus_limit,
          description: resp.data[0]?.description,

          request_info: resp.data[0]?.request_info,
        });
        form.setFieldsValue({
          // id: params.id,
          currency: resp.data[0]?.currency,
          quantity: resp.data[0]?.quantity,
          name: resp.data[0]?.name,
          collection: resp.data[0]?.collection,
          rate: resp.data[0]?.rate,
          rate_type: resp.data[0]?.rate_type,
          rate_capped: resp.data[0]?.rate_capped,
          rate_amount_plus: resp.data[0]?.rate_amount_plus,
          rate_amount_plus_limit: resp.data[0]?.rate_amount_plus_limit,

          min_amount: resp.data[0]?.min_amount,
          max_amount: resp.data[0]?.max_amount,
          min_duration: resp.data[0]?.min_duration,
          max_duration: resp.data[0]?.max_duration,
          active: resp.data[0]?.active,

          instalment_allowed: resp.data[0]?.instalment?.allowed,
          instalment_min_count: resp.data[0]?.instalment?.min_count,
          instalment_max_count: resp.data[0]?.instalment?.max_count,
          instalment_min_duration: resp.data[0]?.instalment?.min_duration,
          instalment_max_duration: resp.data[0]?.instalment?.max_duration,
          instalment_min_amount: resp.data[0]?.instalment?.min_amount,
          instalment_max_amount: resp.data[0]?.instalment?.max_amount,
          // instalment_penalty: resp.data[0]?.instalment?.penalty,

          auto_approve: resp.data[0]?.auto_approve,
          auto_decline: resp.data[0]?.auto_decline,
          send_late_repayment_sms: resp.data[0]?.send_late_repayment_sms,
          send_late_repayment_email: resp.data[0]?.send_late_repayment_email,
          auto_debit_card_on_repayment_date:
            resp.data[0]?.auto_debit_card_on_repayment_date,
          auto_debit_bank_account_on_repayment_date:
            resp.data[0]?.auto_debit_bank_account_on_repayment_date,
          card_meter: resp.data[0]?.card_meter,
          bank_meter: resp.data[0]?.bank_meter,
          allow_new_request_on_current_loan:
            resp.data[0]?.allow_new_request_on_current_loan,
          send_request_status_sms: resp.data[0]?.send_request_status_sms,
          send_request_status_email: resp.data[0]?.send_request_status_email,
          send_relation_overdue_repayment_sms:
            resp.data[0]?.send_relation_overdue_repayment_sms,
          penalty: resp.data[0]?.penalty,
          penalty_type: resp.data[0]?.penalty_type,
          penalty_capped: resp.data[0]?.penalty_capped,
          penalty_amount_plus: resp.data[0]?.penalty_amount_plus,
          penalty_amount_plus_limit: resp.data[0]?.penalty_amount_plus_limit,
          description: resp.data[0]?.description,
        });
        setIsFetching(false);
      }
    } catch (error) {
      console.log(error);
      handleResponseError(error, toast);
      navigate("/d/services/loan");
    }
  }

  useEffect(() => {
    getLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateLoan() {
    setIsSending(true);
    console.log(upadeLoanInput);
    try {
      const resp = await http("services/addon/loan/update", {
        ...upadeLoanInput,
        id: params.id,
        currency: upadeLoanInput?.currency?.toLowerCase(),
      });
      console.log("update resp", resp.data);
      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/d/services/loan");
    } catch (error) {
      console.log(error);
      setIsSending(false);
      handleResponseError(error, toast);
    }
  }

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    fieldName: string
  ) => {
    setUpdateLoanInput((prevInput) => ({
      ...prevInput,
      [fieldName]: event.target.value,
    }));
  };

  const handleInputChange_instalment = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    setUpdateLoanInput((prevInput) => ({
      ...prevInput,
      instalment: { ...prevInput.instalment, [fieldName]: event.target.value },
    }));
  };

  const handleInputChange_require_info = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string,
    index: number
  ) => {
    if (fieldName !== "options") {
      setUpdateLoanInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index ? { ...info, [fieldName]: event.target.value } : info
        ),
      }));
    } else {
      setUpdateLoanInput((prevInput) => ({
        ...prevInput,
        request_info: prevInput.request_info.map((info, i) =>
          i === index
            ? {
                ...info,
                [fieldName as any]: formatRequiredInfoOptionsStore(
                  event.target.value
                ),
              }
            : info
        ),
      }));
    }
  };

  function deleteRequestInfo(index: number) {
    setUpdateLoanInput((prevInput) => ({
      ...prevInput,
      request_info: prevInput.request_info.filter((_, i) => i !== index),
    }));
  }

  function addMoreRequestInfo() {
    setUpdateLoanInput((prevInput) => ({
      ...prevInput,
      request_info: [
        ...prevInput.request_info,
        {
          name: "",
          required: "",
          options: [],
          value: "",
          type: "",
        },
      ],
    }));
  }

  function formatRequiredInfoOptionsView(data: string[]): string {
    if (data.length > 0) {
      let r = "";
      data.forEach((e, i) => {
        if (i !== data.length - 1) {
          r += e + ",";
        } else {
          r += e;
        }
      });
      return r;
    }
    return "";
  }

  function formatRequiredInfoOptionsStore(data: string): string[] {
    const arr = data.split(",");
    let s: string[] = [];
    if (arr.length > 0) {
      arr.forEach((e) => {
        s.push(e);
      });
      return s;
    }
    return [];
  }

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/overview");
            }}
          >
            Business
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/details");
            }}
          >
            Services
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/d/services/loan");
            }}
          >
            Loan
          </div>
          <div>
            <FaAngleRight />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(`/d/services/loan/update/${params?.id}`);
            }}
          >
            Update
          </div>
        </div>
      </div>

      <div className="mb-8 text-xl">Update Loan</div>
      <hr className="mb-8 border-slate-300 dark:border-neutral-700" />

      {!isFetching ? (
        <div className="w-full mb-10">
          <Form className="mb-8" form={form} onFinish={updateLoan}>
            <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold dark:text-white">
              Loan Information
            </div>
            <div className="grid grid-cols-1 gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4">
              {Object.keys(upadeLoanInput)
                .filter(
                  (f) =>
                    f !== "instalment" &&
                    f !== "request_info" &&
                    f !== "description"
                )
                .map((fieldName, i) => (
                  <div key={i} className="">
                    <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px] flex justify-between">
                      {upperCaseFirst(removeUnderscore(fieldName))}
                      {checkForInfo(fieldName)}
                    </div>
                    {Object.keys(isSelectTag).includes(fieldName) ? (
                      <Form.Item
                        name={fieldName}
                        rules={[
                          {
                            required: true,
                            message: `${upperCaseFirst(
                              removeUnderscore(fieldName)
                            )} is required`,
                          },
                        ]}
                      >
                        <select
                          value={(upadeLoanInput as any)[fieldName]}
                          className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                          onChange={(event) => {
                            handleInputChange(event, fieldName);
                            form.setFieldValue(fieldName, event.target.value);
                          }}
                          disabled={isSending}
                        >
                          <option value="">Select</option>
                          {((isSelectTag as any)[fieldName] as string[]).map(
                            (e, k) => (
                              <option value={e} key={k}>
                                {e}
                              </option>
                            )
                          )}
                        </select>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name={fieldName}
                        rules={[
                          {
                            required: true,
                            message: `${upperCaseFirst(
                              removeUnderscore(fieldName)
                            )} is required`,
                          },
                        ]}
                      >
                        <input
                          type="text"
                          value={(upadeLoanInput as any)[fieldName]}
                          className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                          onChange={(event) => {
                            handleInputChange(event, fieldName);
                            form.setFieldValue(fieldName, event.target.value);
                          }}
                          placeholder={upperCaseFirst(
                            removeUnderscore(fieldName)
                          )}
                          disabled={isSending}
                        />
                      </Form.Item>
                    )}
                  </div>
                ))}

              <div className="col-span-1 mb-8 md:col-span-2 lg:col-span-4">
                <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px] flex">
                  Description
                  {checkForInfo("description")}
                </div>

                <Form.Item
                  name={"description"}
                  rules={[
                    {
                      required: true,
                      message: `${upperCaseFirst(
                        removeUnderscore("description")
                      )} is required`,
                    },
                  ]}
                >
                  <textarea
                    value={upadeLoanInput.description}
                    className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                    onChange={(event) => {
                      handleInputChange(event, "description");
                      form.setFieldValue("description", event.target.value);
                    }}
                    placeholder="Description"
                    rows={3}
                    disabled={isSending}
                  ></textarea>
                </Form.Item>
              </div>
            </div>

            <div className="inline-block border-b-2 border-purple-800 pb-[3px] mb-5 text-sm font-bold dark:text-white">
              Installment
            </div>
            <div className="grid grid-cols-1 gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4">
              {Object.keys(upadeLoanInput?.instalment ?? {})?.map(
                (fieldName, i) => (
                  <div key={i} className="">
                    <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px] flex justify-between">
                      {upperCaseFirst(removeUnderscore(fieldName))}
                      {checkForInfo(fieldName)}
                    </div>
                    {Object.keys(isSelectTag).includes(fieldName) ? (
                      <Form.Item
                        name={`instalment_${fieldName}`}
                        rules={[
                          {
                            required: true,
                            message: `${upperCaseFirst(
                              removeUnderscore(fieldName)
                            )} is required`,
                          },
                        ]}
                      >
                        <select
                          value={
                            (upadeLoanInput.instalment?.allowed as any)[
                              fieldName
                            ]
                          }
                          className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                          onChange={(event) =>
                            handleInputChange_instalment(event, fieldName)
                          }
                          disabled={isSending}
                        >
                          <option value="">Select</option>
                          {((isSelectTag as any)[fieldName] as string[]).map(
                            (e, k) => (
                              <option value={e} key={k}>
                                {e}
                              </option>
                            )
                          )}
                        </select>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        name={`instalment_${fieldName}`}
                        rules={[
                          {
                            required:
                              form.getFieldValue("allowed") === "Yes"
                                ? true
                                : false,
                            message: `${upperCaseFirst(
                              removeUnderscore(fieldName)
                            )} is required`,
                          },
                        ]}
                      >
                        <input
                          type="text"
                          value={(upadeLoanInput.instalment as any)[fieldName]}
                          className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                          onChange={(event) =>
                            handleInputChange_instalment(event, fieldName)
                          }
                          placeholder={upperCaseFirst(
                            removeUnderscore(fieldName)
                          )}
                          disabled={isSending}
                        />
                      </Form.Item>
                    )}
                  </div>
                )
              )}
            </div>

            <div className="mb-10">
              <div className="flex items-center justify-between mb-5 text-sm">
                <div className="border-b-2 border-purple-800 pb-[3px] font-bold dark:text-white">
                  Request information
                </div>
                <div
                  className="text-xs font-bold text-purple-700 cursor-pointer dark:text-purple-500"
                  onClick={() => addMoreRequestInfo()}
                >
                  Add section
                </div>
              </div>
              {upadeLoanInput?.request_info.length > 0 ? (
                upadeLoanInput?.request_info.map((request_info, index) => (
                  <div
                    key={index}
                    className="p-3 mb-3 rounded-lg bg-slate-100 dark:bg-neutral-800"
                  >
                    <div className="grid grid-cols-1 gap-5 mb-3 md:grid-cols-2 lg:grid-cols-4">
                      {Object.keys(request_info).map((fieldName, i) => (
                        <div key={i} className="">
                          <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]">
                            {upperCaseFirst(removeUnderscore(fieldName))}
                          </div>
                          {Object.keys(isSelectTagRequireInfo).includes(
                            fieldName
                          ) ? (
                            <select
                              value={
                                fieldName === "options"
                                  ? formatRequiredInfoOptionsView(
                                      (
                                        upadeLoanInput.request_info[
                                          index
                                        ] as any
                                      )[fieldName]
                                    )
                                  : (upadeLoanInput.request_info[index] as any)[
                                      fieldName
                                    ]
                              }
                              className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                              onChange={(event) =>
                                handleInputChange_require_info(
                                  event,
                                  fieldName,
                                  index
                                )
                              }
                              disabled={isSending}
                            >
                              <option value="">Select</option>
                              {(
                                (isSelectTagRequireInfo as any)[
                                  fieldName
                                ] as string[]
                              ).map((e, k) => (
                                <option value={e} key={k}>
                                  {e}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              type="text"
                              value={
                                fieldName === "options"
                                  ? formatRequiredInfoOptionsView(
                                      (
                                        upadeLoanInput.request_info[
                                          index
                                        ] as any
                                      )[fieldName]
                                    )
                                  : (upadeLoanInput.request_info[index] as any)[
                                      fieldName
                                    ]
                              }
                              className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                              onChange={(event) =>
                                handleInputChange_require_info(
                                  event,
                                  fieldName,
                                  index
                                )
                              }
                              placeholder={
                                fieldName === "options"
                                  ? "option1, option2, option3"
                                  : upperCaseFirst(removeUnderscore(fieldName))
                              }
                              disabled={isSending}
                            />
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="mb-2">
                      <div
                        className="text-xs text-red-500 cursor-pointer"
                        onClick={() => deleteRequestInfo(index)}
                      >
                        delete section
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-3 mb-3 text-sm rounded-lg bg-slate-100 dark:bg-neutral-800">
                  Click on "Add section" to add form group
                </div>
              )}
            </div>

            <div>
              <button
                className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                // onClick={() => updateLoan()}
                disabled={isSending}
                type="submit"
              >
                {isSending ? (
                  <ImSpinner8 className="w-5 h-5 animate-spin" />
                ) : (
                  <span>Submit</span>
                )}
              </button>
            </div>
          </Form>
        </div>
      ) : (
        <div className="mb-10 h-[300px] w-full flex justify-center items-center">
          <ImSpinner8 className="w-10 h-10 text-purple-800 animate-spin" />
        </div>
      )}
    </>
  );
}

export default BusinessServicesLoanUpdateClient;
