'use client'

import http from '@/lib/http';
import { handleResponseError } from '@/utils/errors';
import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { ImSpinner8 } from 'react-icons/im';
import { Link, useNavigate } from 'react-router-dom';

function RetrieveAccountCient() {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const toast = useToast();

  async function retrieveAccount() {
    setIsFetching(true);
    try {
      const resp = await http("account/retrieve-account", { email }, process.env.REACT_APP_PUBLIC_KEY);
      console.log(resp)
      setEmail("");
      toast({
        title: 'Success',
        description: resp.message,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      navigate('/auth/login');
    } catch (error) {
      console.log(error);  
      setIsFetching(false);        
      handleResponseError(error, toast);
    }
  }

  return (
    <>
      <div className='flex justify-center h-full'>
        <div className='mt-[150px]'>
          <div className='w-[300px] md:w-[400px] lg:w-[450px]px-6 py-5'>
            <div className='font-bold mb-10 text-lg border-b-[5px] border-b-purple-800 inline-block'>Retrieve account</div>

            <div className='mb-5'>
              <div className='text-xs font-bold text-slate-600 dark:text-neutral-500 mb-[5px]'>Email</div>
              <input type="email" className='px-3 py-3 text-xs w-full border-2 border-neutral-600 dark:bg-neutral-700 outline-none' onChange={(e) => setEmail(e.target.value)} placeholder='Enter email' disabled={isFetching} />
            </div>

            <div className='mb-3'>
              <button className='px-10 py-2 bg-purple-800 text-sm text-neutral-100 w-full disabled:bg-opacity-50 flex justify-center' onClick={() => retrieveAccount()} disabled={isFetching}>
                {
                  isFetching ? <ImSpinner8 className='animate-spin w-5 h-5' /> : <span>Submit</span>
                }
              </button>
            </div>
            <div className='text-xs flex gap-3'>
              <div>Remember your credentials ?</div>
              <Link to={'/auth/login'} className='text-purple-800 font-bold'>Back to Sign in</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RetrieveAccountCient