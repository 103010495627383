"use client";

import React, { useEffect, useMemo, useState } from "react";
import UpdatePaymentsRequestModal from "@/components/modals/UpdatePaymentsRequestModal";
import http from "@/lib/http";
import { Skeleton, useToast } from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa6";
import AutoGenTableViewModal from "@/components/modals/AutoGenTableViewModal";
import FilterForm from "@/components/FilterForm";
import { IPaymentsRequest } from "@/types/global.types";
import { ImSpinner8 } from "react-icons/im";
import { handleResponseError } from "@/utils/errors";

function BusinessServicesPaymentsRequestsClient() {
  const toast = useToast();

  const [details, setDetails] = useState<IPaymentsRequest[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [generating, setGenerating] = useState(false);

  const [paginationInput, setPaginationInput] = useState<any>();
  const filterInputs = {
    currency: "NGN",
    account_id: "",
    status: "",
    reference_code: "",
    order_by: "",
    // type: "",
    id: "",
    date_filter_from: "",
    date_filter_to: "",
  };
  function setPaginate(resp: any) {
    setPaginationInput({
      counts: resp.counts,
      totalPages: resp.totalPages,
      currentPage: resp.currentPage,
      listPerPage: resp.listPerPage,
    });
  }

  async function fetchServices() {
    try {
      const resp = await http("services/addon/payments/request/list", {
        limit: 10,
      });
      console.log("resp", resp);
      setDetails(resp.data);
      setPaginate(resp);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      setIsFetching(false);
    }
  }

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDetails = useMemo(() => {
    if (details.length === 0) return [];
    //  if(search === "");
    const sorted_ =
      sort === "date-asc"
        ? details
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(a.created_on).getTime() -
                new Date(b.created_on).getTime()
            )
        : sort === "date-desc"
        ? details
            .slice()
            .sort(
              (a: any, b: any) =>
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
            )
        : sort === "z-a"
        ? details
            .slice()
            .sort((a: any, b: any) =>
              b.requester?.firstname.toLowerCase() <
              a.requester?.firstname.toLowerCase()
                ? -1
                : 1
            )
        : details
            .slice()
            .sort((a: any, b: any) =>
              a.requester?.firstname.toLowerCase() <
              b.requester?.firstname.toLowerCase()
                ? -1
                : 1
            );

    const filtered_ =
      search.length > 0
        ? sorted_.filter((e: any) =>
            e.requester?.firstname.toLowerCase().includes(search.toLowerCase())
          )
        : sorted_;

    return filtered_;
  }, [search, sort, details]);

  const generatePDF = async (filters: any) => {
    try {
      setGenerating(true);

      const resp = await http("services/addon/payments/request/generate-pdf", {
        ...filters,
      });

      toast({
        title: "Success",
        description: resp.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setGenerating(false);
    } catch (error) {
      setGenerating(false);
      handleResponseError(error, toast);
    }
  };

  return (
    <>
      <div className="max-w-full mb-10 overflow-auto md:max-w-full">
        <div className="flex items-center gap-3 text-xs text-slate-500">
          <div>Business</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Services</div>
          <div>
            <FaAngleRight />
          </div>
          <div>Payments requests</div>
        </div>
      </div>

      <div className="mb-10 text-xl">Payments Requests</div>

      <div className="flex items-center gap-5 mb-8">
        <input
          value={search}
          type="text"
          className="px-3 py-3 sm:w-[400px] w-[190px] text-sm border-2 border-neutral-600 dark:bg-neutral-700"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Enter Search Keyword"
        />
        <div className="flex-1">
          <div className="text-xs">Sort by</div>
          <select
            className="text-xs p-1 dark:bg-neutral-700 w-[110px]"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
            <option value="date-asc">Date (Ascending)</option>
            <option value="date-desc">Date (Descending)</option>
          </select>
        </div>
      </div>

      <hr className="mb-6 border-slate-300 dark:border-neutral-700" />

      <FilterForm
        url="services/addon/payments/request/list"
        inputs={filterInputs}
        paginationInput={paginationInput}
        isLoading={isFetching}
        setIsLoading={setIsFetching}
        setState={setDetails}
        setPaginationInput={setPaginationInput}
        inputtedIsSelectTag={{
          status: ["Pending", "Processing", "Failed", "Successful", "Approved"],
          type: ["DIRECT", "ALTERNATIVE"],
        }}
        generatePDF={generatePDF}
        generatingPDF={generating}
      />

      <div className="w-full mb-10">
        <div className="relative overflow-auto max-h-[500px] flex-1">
          <table className="min-w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-neutral-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Requester
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Reference code
                </th>
                <th scope="col" className="px-6 py-3">
                  Settled
                </th>
                <th scope="col" className="px-6 py-3">
                  Currency
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!isFetching
                ? filteredDetails.map((d, k) => (
                    <tr
                      key={k}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4">{d.id}</td>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {d.requester.firstname} {d.requester.lastname}
                      </th>
                      <td className="px-6 py-4">{d.total_amount}</td>
                      <td className="px-6 py-4">{d.reference_code}</td>
                      <td className="px-6 py-4">{d.settled}</td>
                      <td className="px-6 py-4 uppercase">{d.currency}</td>
                      <td className="px-6 py-4">{d.status}</td>
                      <td className="flex gap-3 px-6 py-4">
                        <AutoGenTableViewModal title="Request" data={d}>
                          <span className="font-bold text-blue-600">View</span>
                        </AutoGenTableViewModal>
                        <UpdatePaymentsRequestModal data={d}>
                          <span className="font-bold text-blue-600">
                            Update
                          </span>
                        </UpdatePaymentsRequestModal>
                      </td>
                    </tr>
                  ))
                : Array.from(Array(5)).map((k, i) => (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-neutral-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                      <td className="px-6 py-4">
                        <Skeleton h={"20px"} />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default BusinessServicesPaymentsRequestsClient;
