import http from "@/lib/http";
import { handleResponseError } from "@/utils/errors";
import { formatDate, removeUnderscore, upperCaseFirst } from "@/utils/helpers";
import { useToast } from "@chakra-ui/react";
import classNames from "classnames";
import React, { useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

type Props = {
  url: string;
  inputs: any;
  optionalInputs?: object;
  paginationInput: any;
  inputtedIsSelectTag?: { [key: string]: string[] };
  isLoading: boolean;
  limit?: number;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setState: React.Dispatch<React.SetStateAction<any>>;
  setPaginationInput: React.Dispatch<React.SetStateAction<any>>;
  typeDefaultValue?: string;
  generatePDF?: (filters: any) => void;
  generatingPDF?: boolean;
};

function FilterForm({
  url,
  inputs,
  limit = 10,
  paginationInput,
  inputtedIsSelectTag = {},
  isLoading,
  optionalInputs = {},
  setIsLoading,
  setState,
  setPaginationInput,
  typeDefaultValue,
  generatePDF,
  generatingPDF = false,
}: Props) {
  const toast = useToast();

  const defaultIsSelectTag = {
    active: ["Yes", "No"],
    order_by: ["OLD", "NEW"],
    account: ["user", "business"],
    type: ["Credit", "Debit"],
    country_code: ["NG"],
    currency: ["NGN", "USD"],
  };

  const isSelectTag = { ...defaultIsSelectTag, ...inputtedIsSelectTag };

  const [filterInput, setFilterInput] = useState<object>(inputs);

  const handleInputChange = (val: string, fieldName: string) => {
    setFilterInput((prevInput) => ({ ...prevInput, [fieldName]: val }));
  };

  async function sendRequest(page?: string, pageMax?: number) {
    if (
      page &&
      pageMax &&
      (parseInt(page) === 0 || parseInt(page) === pageMax + 1)
    )
      return false;
    setIsLoading(true);
    try {
      const resp = await http(url, {
        ...filterInput,
        limit: limit,
        ...optionalInputs,
        page: page || paginationInput.currentPage || 0,
        type: typeDefaultValue ?? undefined,
        //@ts-ignore
        currency: filterInput?.currency?.toLowerCase(),
      });
      setState(resp.data);
      // console.log("filtered", resp)
      setPaginationInput({
        counts: resp.counts,
        totalPages: resp.totalPages,
        currentPage: resp.currentPage,
        listPerPage: resp.listPerPage,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, toast);
    }
  }

  const sendRequestGenerate = (page?: string, pageMax?: number) => {
    if (
      page &&
      pageMax &&
      (parseInt(page) === 0 || parseInt(page) === pageMax + 1)
    ) {
      return false;
    }

    generatePDF?.({
      ...filterInput,
      limit: limit,
      ...optionalInputs,
      page: page || paginationInput.currentPage || 0,
      type: typeDefaultValue ?? undefined,
      //@ts-ignore
      currency: filterInput?.currency?.toLowerCase(),
    });
  };

  // function sendFilterRquest() {
  //     sendRequest('0');
  //     setPaginationInput((p: object) => ({...p, currentPage: 0}));
  // }

  return (
    <div className="mb-5">
      <div className="mb-5 text-xs font-bold text-purple-500">
        Filter Result
      </div>
      <div className="grid grid-cols-2 gap-3 mb-8 md:grid-cols-4">
        {Object.keys(inputs).map((fieldName, k) => (
          <div key={k} className="">
            <div className="text-xs font-bold text-slate-500 dark:text-neutral-400 mb-[2px]">
              {fieldName === "id"
                ? "Product id"
                : upperCaseFirst(removeUnderscore(fieldName))}
            </div>
            {Object.keys(isSelectTag).includes(fieldName) ? (
              <select
                className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                value={(filterInput as any)[fieldName]}
                onChange={(e) => {
                  handleInputChange(e.target.value, fieldName);
                  console.log(e.target.value, fieldName);
                }}
                disabled={isLoading}
              >
                <option value="">Select</option>
                {((isSelectTag as any)[fieldName] as string[]).map((o, i) => (
                  <option value={o}>{o}</option>
                ))}
              </select>
            ) : fieldName === "date_filter_from" ||
              fieldName === "date_filter_to" ? (
              <input
                type="datetime-local"
                className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                onChange={(e) =>
                  handleInputChange(formatDate(e.target.value), fieldName)
                }
                disabled={isLoading}
              />
            ) : (
              <input
                type="text"
                className="w-full px-3 py-3 text-xs border-2 outline-none border-neutral-600 dark:bg-neutral-700"
                value={(filterInput as any)[fieldName]}
                onChange={(e) => handleInputChange(e.target.value, fieldName)}
                disabled={isLoading}
              />
            )}
          </div>
        ))}
        <div className={`md:pt-0 ${generatePDF !== undefined ? "col-span-2" : ""}`}>
          <div className="text-opacity-0 text-slate-500 dark:text-neutral-400 dark:text-opacity-0">
            -
          </div>
          <div className="flex gap-2">
            <button
              className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
              onClick={() => sendRequest("0")}
              disabled={isLoading || generatingPDF}
            >
              {isLoading ? (
                <ImSpinner8 className="w-5 h-5 animate-spin" />
              ) : (
                <span>Submit</span>
              )}
            </button>

            {generatePDF !== undefined && (
              <>
                <button
                  className="flex justify-center px-10 py-2 text-sm bg-purple-800 text-neutral-100 disabled:bg-opacity-50"
                  onClick={() => {
                    sendRequestGenerate();
                  }}
                  disabled={isLoading || generatingPDF}
                >
                  {generatingPDF ? (
                    <ImSpinner8 className="w-5 h-5 animate-spin" />
                  ) : (
                    <span>Generate Statement</span>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <hr className="mb-3 border-slate-300 dark:border-neutral-700" />

      {paginationInput &&
      Object.keys(paginationInput).length > 0 &&
      paginationInput.totalPages &&
      paginationInput.totalPages > 1 ? (
        <div className="mb-1">
          <div className="flex items-center gap-3">
            <div
              onClick={() =>
                sendRequest(
                  `${paginationInput.currentPage - 1}`,
                  paginationInput.totalPages
                )
              }
              className={classNames(
                "px-2 py-1 rounded-full font-bold text-sm cursor-pointer border-2 border-purple-500"
              )}
            >
              <FaAngleLeft />
            </div>

            {paginationInput.totalPages > 6 &&
              paginationInput.currentPage > 2 && (
                <>
                  <div
                    onClick={() => sendRequest(`1`, paginationInput.totalPages)}
                    className={classNames(
                      "px-2 py-0 rounded-full font-bold text-sm cursor-pointer"
                    )}
                  >
                    1
                  </div>
                  <div>..</div>
                </>
              )}

            {Array.from(Array(paginationInput.totalPages)).map((_, i) => (
              // i !== 0 &&
              <>
                {i > paginationInput.currentPage - 3 &&
                  i <
                    paginationInput.currentPage +
                      (paginationInput.currentPage <= 1 ? 3 : 1) && (
                    <div
                      key={i}
                      onClick={() =>
                        sendRequest(`${i + 1}`, paginationInput.totalPages)
                      }
                      className={classNames(
                        "px-2 py-0 rounded-full font-bold text-sm cursor-pointer",
                        {
                          "bg-purple-500":
                            paginationInput.currentPage === i + 1 ||
                            (paginationInput.currentPage === 0 && i === 0),
                        }
                      )}
                    >
                      {i + 1}
                    </div>
                  )}
              </>
            ))}

            {paginationInput.totalPages > 6 &&
              paginationInput.currentPage < paginationInput.totalPages - 1 && (
                <>
                  <div>..</div>
                  <div
                    onClick={() =>
                      sendRequest(
                        `${paginationInput.totalPages}`,
                        paginationInput.totalPages
                      )
                    }
                    className={classNames(
                      "px-2 py-0 rounded-full font-bold text-sm cursor-pointer"
                    )}
                  >
                    {paginationInput.totalPages}
                  </div>
                </>
              )}

            <div
              onClick={() =>
                sendRequest(
                  `${paginationInput.currentPage + 1}`,
                  paginationInput.totalPages
                )
              }
              className={classNames(
                "px-2 py-1 rounded-full font-bold text-sm cursor-pointer border-2 border-purple-500"
              )}
            >
              <FaAngleRight />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default FilterForm;
